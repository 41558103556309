import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logout } from "../actions/auth";

class Logout extends Component {
    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        this.props.onClick();
    }

    render() {
        return (
            <a href="#/" role="button" onClick={this.handleLogout}>
                {this.props.lang[this.props.lang.locales].navigations.logout}
            </a>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch, { history }) => {
    const onClick = () =>
        dispatch(
            logout({
                history
            })
        );
    return {
        onClick
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Logout)
);
