import React, { Component } from "react";
// import SignUpModal from "./SignUpModal";
import ForgetPasswordModal from "./ForgetPasswordModal";
import { SignupModal } from '../modals/index.js';

class Modals extends Component {
  render() {
    return (
      <section className="modal-section">
        <SignupModal />
        <ForgetPasswordModal />
      </section>
    );
  }
}

export default Modals;
