import {
    SET_REWARDS,
    RESET_REWARDS
} from '../actions/rewards.js'

const initialState = {}

export default (state = initialState, {
    type,
    payload
}) => {
    switch (type) {
        case SET_REWARDS:
            let newState = {}
            for (const reward of payload) {
                const { Category } = reward
                newState[Category] = newState[Category] || []
                newState[Category] = newState[Category].concat(reward)
            }
            return newState
        case RESET_REWARDS:
            return initialState
        default:
            return state
    }
}