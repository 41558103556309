import {
    SET_REWARD_CATEGORIES,
    RESET_REWARD_CATEGORIES
} from "../actions"

const initialState = []

export default (state = initialState, {
    type,
    payload
}) => {
    switch (type) {
        case SET_REWARD_CATEGORIES:
            return [
                ...payload
            ];
        case RESET_REWARD_CATEGORIES:
            return initialState;
        default:
            return state;
    }
}