import { reset } from "redux-form";
import Swal from "sweetalert2";

export const GET_ACCOUNT = "GET_ACCOUNT";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const RESET_ACCOUNT = "RESET_ACCOUNT";

export const getAccount = () => (dispatch, getState) => {
    return getState().account;
};

export const setAccount = payload => {
    return {
        type: SET_ACCOUNT,
        payload
    };
};

export const resetAccount = () => {
    return {
        type: RESET_ACCOUNT
    };
};

export const fetchAccount = () => (dispatch, getState) => {
    const axios = require("./axios")();
    const token = localStorage.getItem("token");
    if (!token) {
        return;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.get("/me").then(({ data }) => dispatch(setAccount(data)));
};

export const submitProfile = ({ values }) => (dispatch, getState) => {
    const axios = require("./axios")();
    const token = localStorage.getItem("token");
    if (!token) {
        return;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    let formData = new FormData();

    for (const key in values) {
        if (values.hasOwnProperty(key)) {
            formData.append(key, values[key]);
        }
    }

    axios
        .put("/me", formData)
        .then(({ status, data }) => {
            if (status >= 200 && status < 400) {
                const { lang } = getState();
                const { locales } = lang;
                const { notifications } = lang[locales];

                dispatch(fetchAccount());
                Swal.fire({
                    type: "success",
                    title: notifications.changeProfile.success,
                    onClose: () => window.location.reload()
                });
            }
        })
        .catch(error => console.error(error));
};

export const submitAgent = ({ values, history }) => (dispatch, getState) => {
    const axios = require("./axios")();
    const token = localStorage.getItem("token");
    if (!token) {
        return;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
        .put("/me/change-agent", values)
        .then(({ data, status }) => {
            if (status >= 200 && status < 400) {
                const { lang } = getState();
                const { locales } = lang;
                const { notifications } = lang[locales];
                dispatch(reset("changeAgent"));
                history.push("/profile");
                Swal.fire({
                    type: "success",
                    title: notifications.changeAgent.success,
                    onClose: () => window.location.reload()
                });
            }
        })
        .catch(error => console.log(error));
};

export const submitPassword = ({ values, history }) => (dispatch, getState) => {
    const axios = require("./axios")();
    const token = localStorage.getItem("token");
    if (!token) {
        return;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
        .put("/me/change-password", values)
        .then(({ data, status }) => {
            if (status >= 200 && status < 400) {
                const { lang } = getState();
                const { locales } = lang;
                const { notifications } = lang[locales];

                dispatch(reset("changePassword"));
                history.push("/profile");
                Swal.fire({
                    type: "success",
                    title: notifications.changePassword.success,
                    onClose: () => window.location.reload()
                });
            }
        })
        .catch(error => console.log(error));
};

export const resetPassword = ({ values, history, match }) => (
    dispatch,
    getState
) => {
    const axios = require("./axios")();

    axios
        .put("/me/reset-password", values)
        .then(({ data, status }) => {
            if (status >= 200 && status < 400) {
                const { lang } = getState();
                const { locales } = lang;
                const { notifications } = lang[locales];

                dispatch(reset("resetPassword"));
                history.push("/profile");
                Swal.fire({
                    type: "success",
                    title: notifications.changePassword.success,
                    onClose: () => window.location.reload()
                });
            }
        })
        .catch(error => console.log(error));
};
