import React from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { asyncValidateLogin, validateLogin } from "../validate";
import { login } from "../actions";

const renderField = props => {
    const {
        input,
        label,
        type,
        meta: { touched, error }
    } = props;
    const classes = ["form-group", "col-xs-12", "col-sm-6", "col-md-4", "col-lg-2"];
    return (
        <div
            className={
                touched && error
                    ? classes.concat(["has-error", "has-feedback"]).join(" ")
                    : classes.join(" ")
            }
            style={{ paddingRight: "1rem" }}
        >
            <label className="control-label sr-only">{label}</label>
            <input
                className="form-control"
                {...input}
                placeholder={label}
                type={type}
                aria-describedby={`${label}Status`}
            />
            {touched && error && (
                <span
                    className="glyphicon glyphicon-remove form-control-feedback"
                    aria-hidden="true"
                />
            )}
            {/* { touched && error && <small id={`${label}Status`}>{ error }</small> } */}
        </div>
    );
};

const LoginBox = ({
    onSubmit,
    handleSubmit,
    pristine,
    error,
    submitting,
    lang
}) => (
    <div className="login-box" id="login-box">
        <div className="container">
            <div className="row">
                <div className="col-xs-12">
                    <div className="login-input-2 text-center">
                        <form
                            className="form-inline"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Field
                                component={renderField}
                                type="email"
                                name="Email"
                                label={lang[lang.locales].forms.login.email}
                            />
                            <Field
                                component={renderField}
                                type="password"
                                name="Password"
                                label={lang[lang.locales].forms.login.password}
                            />
                            <button
                                type="submit"
                                className="btn btn-login"
                                disabled={pristine || submitting || error}
                            >
                                {lang[lang.locales].actions.login}
                            </button>
                        </form>
                        <div
                            className="col-xs-3 col-xs-offset-5"
                            style={{ padding: 0 }}
                        >
                            <p className="forget-password">
                                <button
                                    type="button"
                                    data-toggle="modal"
                                    data-target=".forgetPassword"
                                >
                                    {lang[lang.locales].actions.forget}
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

const mapDispatchToProps = (dispatch, { history, reset }) => {
    return {
        onSubmit: credentials =>
            dispatch(
                login({
                    credentials,
                    history,
                    reset
                })
            )
    };
};

export default withRouter(
    reduxForm({
        form: "login",
        validate: validateLogin,
        asyncValidate: asyncValidateLogin,
        asyncChangeFields: ["Email", "Password"]
    })(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(LoginBox)
    )
);
