export const validateChangeAgent = ({ NewAgentCode, NewUserID }) => {
    const errors = {};
    if (!NewAgentCode) {
        errors.NewAgentCode = `Required`;
    }
    if (!NewUserID) {
        errors.NewUserID = `Required`;
    }

    return errors;
};

export const asyncValidateChangeAgent = async values => {
    try {
        const axios = require("../actions/axios")();
        const { data: result } = await axios.post(
            "/validate/changeAgent",
            values
        );
        if (Object.keys(result).length > 0) {
            throw result;
        }
        return undefined;
    } catch (err) {
        throw err;
    }
};
