import {
    SET_TRANSACTION,
    RESET_TRANSACTION
} from "../actions/transactions"

const initialState = {}

export default (state = initialState, {
    type,
    payload
}) => {
    switch (type) {
        case SET_TRANSACTION:
            return {
                ...payload
            }
        case RESET_TRANSACTION:
            return initialState
        default:
            return state
    }
}