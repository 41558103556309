import Swal from "sweetalert2";

export const GET_CONTACT = "GET_CONTACT";
export const SET_CONTACT = "SET_CONTACT";
export const RESET_CONTACT = "RESET_CONTACT";

export const getContact = () => (dispatch, getState) => {
    return getState().contact;
};

export const setContact = payload => {
    return {
        type: SET_CONTACT,
        payload
    };
};

export const resetContact = () => {
    return {
        type: RESET_CONTACT
    };
};

export const fetchContact = () => async (dispatch, getState) => {
    try {
        const axios = require("./axios")();
        const { status, data } = await axios.get("/contact")();
        if (status !== 200) {
            return false;
        }
        return dispatch(setContact(data));
    } catch (error) {
        return false;
    }
};

export const sendContact = ({ values, history, ...rest }) => async (
    dispatch,
    getState
) => {
    try {
        const axios = require("./axios")();
        const { status } = await axios.post("/contact", {
            values
        });
        const { lang } = getState();
        const { locales } = lang;
        const { notifications } = lang[locales];

        if (status >= 200 && status < 400) {
            history.push("/");
            Swal.fire({
                type: "success",
                title: notifications.contact.success
            });
        } else if (status > 400) {
            Swal.fire({
                type: "success",
                title: notifications.contact.error
            });
        }
        return true;
    } catch (error) {
        return false;
    }
};
