import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchResetPassword, resetResetPassword, resetPassword } from "../../actions/resetPassword";

import MainPage from './MainPage';

class ResetPasswordPage extends Component {
  componentWillMount() {
    const {
      match: {
        params: { token }
      },
      history
    } = this.props;    
    this.props.fetchResetPassword({
      token,
      history
    });
  }

  componentWillUnmount() {
    this.props.resetResetPassword();
  }
  render() {
    return (
        <MainPage>
        <form onSubmit={this.props.handleSubmit}>
          <section className="resetpassword-section">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <h1>Reset Password</h1>
                  <div className="resetpassword-box2">
                    <div className="row">
                      <div className="col-xs-12">
                        <div className="reset-input text-center">
                          <div className="email-name-reset">
                            <p>Update Password : {this.props.resetPassword.Email} </p>
                          </div>
                          <Field
                            component="input"
                            type="password"
                            name="Password"
                            label="Password"
                            placeholder="Password"
                          />
                          <Field
                            component="input"
                            type="password"
                            name="PasswordConfirm"
                            label="Ulang Kata Sandi"
                            placeholder="Confirm New Password"
                          />
                          <button className="btn btn-login">
                            Reset Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      </form>
        </MainPage>
    );
  }
}

const mapStateToProps = state => {
    return{
        resetPassword: state.resetPassword,
    }
};

const mapDispatchToProps = (dispatch, { history, match }) => {
  return {
    fetchResetPassword: payload => dispatch(fetchResetPassword(payload)),
    resetResetPassword: () => dispatch(resetResetPassword()),
    onSubmit: values =>
      dispatch(
        resetPassword({
          values,
          history,
          match
        })
      )
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: "changePassword",
    })(ResetPasswordPage)
  )
);
