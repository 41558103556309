export const GET_PROMOTIONS = 'GET_PROMOTIONS'
export const SET_PROMOTIONS = 'SET_PROMOTIONS'
export const RESET_PROMOTIONS = 'RESET_PROMOTIONS'

export const GET_PROMOTION = "GET_PROMOTION"
export const SET_PROMOTION = "SET_PROMOTION"
export const RESET_PROMOTION = "RESET_PROMOTION"

export const getPromotions = () => (dispatch, getState) => {
    return getState().promotions
}

export const setPromotions = (payload) => {
    return {
        type: SET_PROMOTIONS,
        payload
    }
}

export const resetPromotions = () => {
    return {
        type: RESET_PROMOTIONS
    }
}

export const getPromotion = () => (dispatch, getState) => {
    return getState().promotion
}

export const setPromotion = (payload) => {
    return {
        type: SET_PROMOTION,
        payload
    }
}

export const resetPromotion = () => {
    return {
        type: RESET_PROMOTION
    }
}

export const fetchPromotions = () => (dispatch, getState) => {
    const Country = require("../config/config").getCountry();
    const axios = require("./axios")()
    axios.get("/promotions", {
            params: {
                Category: "HOTEL",
                AppliesTo: `ALL,${Country},${getState().account.AgentCode}`,
                Active: true,
                published: true,
                all: true,
                showAll: true
            }
        })
        .then(({
            status,
            headers,
            data: rows
        }) => {
            if (status === 200) {
                dispatch(setPromotions({
                    rows,
                }))
            }
        })
}

export const fetchPromotion = ({
    id,
    history
}) => (dispatch, getState) => {
    if (!id) {
        history.push("/")
        return
    }
    const axios = require("./axios")()
    axios.get(`/promotions/${id}`)
        .then(({
            status,
            data
        }) => {
            if (status === 200) {
                dispatch(setPromotion(data))
            }
        })
}