import React from "react";
import { reduxForm, Field, change } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { submitProfile } from "../actions/account";
import { asyncValidateEditProfile, validateEditProfile } from "../validate/editProfile";

const renderField = props => {
    const {
        input,
        label,
        type,
        meta: { touched, error }
    } = props;
    const classes = ["form-group"];
    return (
        <div
            className={
                touched && error
                    ? classes.concat(["has-error", "has-feedback"]).join(" ")
                    : classes.join(" ")
            }
        >
            <label className="control-label sr-only">{label}</label>
            <input
                className="form-control"
                {...input}
                placeholder={label}
                type={type}
                aria-describedby={`${label}Status`}
            />
            {touched && error && (
                <span
                    className="glyphicon glyphicon-remove form-control-feedback"
                    aria-hidden="true"
                />
            )}
            { touched && error && <small id={`${label}Status`}>{ error }</small> }
        </div>
    );
};

const EditUserProfile = ({ handleSubmit, account, onFileChange, lang }) => (
    <form onSubmit={handleSubmit}>
        <div className="col-xs-6">
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeProfile.agent}
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <p>{account.AgentCode}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeProfile.garudaMiles}
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="ClaimGarudaMilesID"
                                label={`${
                                    lang[lang.locales].forms.changeProfile
                                        .garudaMiles
                                }`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeProfile.email}
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            {/* <p>{account.Email}</p> */}
                            <Field
                                component={renderField}
                                type="text"
                                name="Email"
                                label={`${
                                    lang[lang.locales].forms.changeProfile.email
                                }`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeProfile.name}
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="Name"
                                label={`${
                                    lang[lang.locales].forms.changeProfile.name
                                }`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeProfile.bankName}
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="ClaimBankName"
                                label={`${
                                    lang[lang.locales].forms.changeProfile
                                        .bankName
                                }`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeProfile.bankAccount}
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="ClaimBankAccount"
                                label={`${
                                    lang[lang.locales].forms.changeProfile
                                        .bankAccount
                                }`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {
                                lang[lang.locales].forms.changeProfile
                                    .bankAccountName
                            }
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="ClaimBankHolder"
                                label={`${
                                    lang[lang.locales].forms.changeProfile
                                        .bankAccountName
                                }`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeProfile.phone}
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="Phone"
                                label={`${
                                    lang[lang.locales].forms.changeProfile.phone
                                }`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeProfile.address}
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="Address"
                                label={`${
                                    lang[lang.locales].forms.changeProfile
                                        .address
                                }`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeProfile.city}
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="Area"
                                label={`${
                                    lang[lang.locales].forms.changeProfile.city
                                }`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeProfile.birthDate}
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="date"
                                name="Birthdate"
                                label={`${
                                    lang[lang.locales].forms.changeProfile
                                        .birthDate
                                }`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">Business Reg</div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="ClaimBusinessReg"
                                label="Business Reg"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">Passport</div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="ClaimPassport"
                                label="Passport"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">Bank Address</div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="ClaimBankAddress"
                                label="Bank Address"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">Swift Code</div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="ClaimSwift"
                                label="Swift Code"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">Claim Tokopedia Email</div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="ClaimTokopediaEmail"
                                label="Claim Tokopedia Email"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">Claim Ovo Phone Number</div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="ClaimOvoPhone"
                                label="Claim Ovo Phone Number"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">Claim MAP ID</div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="ClaimMapId"
                                label="Claim MAP ID"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">Claim MAP Phone Number</div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="ClaimMapPhone"
                                label="Claim MAP Phone Number"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">Claim MAP Full Name</div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="ClaimMapName"
                                label="Claim MAP Full Name"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeProfile.picture}
                        </div>
                    </div>
                    <div className="col-xs-4">
                        <img
                            className="img-thumbnail"
                            width="110px"
                            height="110px"
                            src={account.Image}
                            alt=""
                        />
                    </div>
                    <div className="col-xs-4">
                        <div className="buttoninputfilecss">
                            <input
                                type="file"
                                onChange={e => onFileChange(e.target.files[0])}
                            />
                            <label htmlFor="file" className="upload">
                                {
                                    lang[lang.locales].forms.changeProfile
                                        .chooseFile
                                }
                            </label>
                            <p className="name-file-choose">
                                {
                                    lang[lang.locales].forms.changeProfile
                                        .noChooseFile
                                }
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="formbutton">
                <div className="row">
                    <div className="col-xs-12">
                        <button type="submit">
                            {lang[lang.locales].forms.changeProfile.save}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
);

const mapStateToProps = state => {
    return {
        initialValues: state.account,
        account: state.account,
        lang: state.lang
    };
};

const mapDispatchToProps = (dispatch, { history }) => {
    return {
        onSubmit: values =>
            dispatch(
                submitProfile({
                    values
                })
            ),
        onFileChange: files => dispatch(change("editProfile", "Image", files))
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "editProfile",
            validate: validateEditProfile,
            asyncValidate: asyncValidateEditProfile,
            enableReinitialize: true,
            multipartForm: true
        })(EditUserProfile)
    )
);
