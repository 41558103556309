import {
    SET_TESTIMONIAL,
    RESET_TESTIMONIAL
} from "../actions/testimonials"

const initialState = {}

export default (state = initialState, {
    type,
    payload
}) => {
    switch (type) {
        case SET_TESTIMONIAL:
            return {
                ...payload
            }
        case RESET_TESTIMONIAL:
            return initialState
        default:
            return state
    }
}