import PropTypes from 'prop-types';
import React from 'react';

const initialClasses = ['form-group'];

const Input = ({input, label, type, helperText, required, showError, meta: { error, form, touched, valid, ...meta}, ...props}) => {
    let classes = [];
    if (touched && error) {
        classes = initialClasses.concat(['has-error', 'has-feedback']);
    } else if (touched && valid) {
        classes = initialClasses.concat(['has-success', 'has-feedback']);
    } else {
        classes = initialClasses;
    }
    if (required) {
        label = `${label}*`;
    }
    return (
        <div className={ classes.join(" ") }>
            <label htmlFor={form + input.name} className="control-label sr-only">{ label }</label>
            <input className="form-control" {...input} placeholder={label} type={type} id={form + input.name} aria-describedby={`${form}${input.name}Status`} required={required} />
            {
                helperText && <span className="help-block">{ helperText }</span>
            }
            { touched && error && <span className="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true" /> }
            { touched && valid && <span className="glyphicon glyphicon-ok form-control-feedback" aria-hidden="true" /> }
            {
                showError && touched && error && <span className="text-danger" role="alert" id={`${form}${input.name}Status`}>{ error }</span>
            }
        </div>
    );
};

Input.propTypes = {
    required: PropTypes.bool,
    showError: PropTypes.bool,
};

Input.defaultProps = {
    required: false,
    showError: false,
};

export default Input;