import {
    GET_TRANSACTIONS,
    SET_TRANSACTIONS,
    SET_TRANSACTIONS_PAGINATION,
    RESET_TRANSACTIONS
} from '../actions/transactions.js'

const initialState = {
    count: 0,
    rows: [],
    pagination: {
        page: 1,
        perPage: 25,
        start: 1,
        end: 25, 
    }
}

export default (state = initialState, {
    type,
    payload
}) => {
    let newState = {};
    switch (type) {
        case SET_TRANSACTIONS:
            newState = {
                ...state,
                ...payload,
                pagination: {
                    ...state.pagination,
                },
            };
            console.log('console here')
            console.log(newState.pagination)
            newState.pagination.start = newState.pagination.page > 1 ? ((newState.pagination.page - 1) * newState.pagination.perPage) + 1 : 1;
            newState.pagination.end = newState.pagination.start + newState.pagination.perPage - 1;
            newState.pagination.end = newState.pagination.end > newState.count ? newState.count : newState.pagination.end;
            return newState;
        case SET_TRANSACTIONS_PAGINATION:
            newState = {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...payload,
                },
            };
            newState.pagination.start = newState.pagination.page > 1 ? ((newState.pagination.page - 1) * newState.pagination.perPage) + 1 : 1;
            newState.pagination.end = newState.pagination.start + newState.pagination.perPage - 1;
            newState.pagination.end = newState.pagination.end > newState.count ? newState.count : newState.pagination.end;
            return newState;
        case RESET_TRANSACTIONS:
            return initialState
        case GET_TRANSACTIONS:
        default:
            return state
    }
}