import React from "react";
import { connect } from "react-redux";
import { fetchRewards, resetRewards } from "../../actions/rewards";
import Carousel from "./Carousel";
// import Modals from "./Modals"
import Modal from "./Modal";

class List extends React.Component {
    componentWillMount() {
        this.props.onMount();
    }

    componentWillUnmount() {
        this.props.onUnmount();
    }

    render() {
        const { rewards } = this.props;
        const { lang } = this.props;
        const { rewardCategories } = this.props;
        const {
            account: { agent }
        } = this.props;
        let carousels = [];

        for (const category of rewardCategories) {
            if (agent !== undefined) {
                if (agent.AgentCode !== "D535" && agent.AgentCode !== "AGID000003") {
                    delete rewards.MAP_FOR_CWT
                }
                if (agent.IsPanorama) {
                    delete rewards.CASH;
                }
                if (agent.IsXML) {
                    return null;
                }
            }

            const { id, Name, NameEn } = category;
            if (!rewards[id]) {
                continue;
            }

            carousels = carousels.concat(
                <Carousel
                    key={id}
                    id={id}
                    rewards={rewards[id].sort((a, b) => a.Points - b.Points)}
                    lang={lang}
                    category={{
                        Name,
                        NameEn
                    }}
                />
            );
        }
        return (
            <section className="tukar-point-reward">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 text-center">
                            <div className="tukarPoint">
                                {
                                    this.props.lang[this.props.lang.locales]
                                        .contents.reward.subheading
                                }
                            </div>
                        </div>
                        <Modal />
                        {carousels}
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        rewards: state.rewards,
        lang: state.lang,
        rewardCategories: state.rewardCategories,
        account: state.account
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onMount: () => dispatch(fetchRewards()),
        onUnmount: () => dispatch(resetRewards())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
