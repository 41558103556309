import React from 'react';
import PropTypes from 'prop-types';

const ModalBody = ({children, ...rest}) => {
    return (
        <div className="modal-body">
            { children }
        </div>
    );
};

ModalBody.propTypes = {
    children: PropTypes.any,
};

ModalBody.defaultProps = {};

export default ModalBody;