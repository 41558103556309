export const GET_TESTIMONIALS = "GET_TESTIMONIALS"
export const SET_TESTIMONIALS = "SET_TESTIMONIALS"
export const RESET_TESTIMONIALS = "RESET_TESTIMONIALS"

export const GET_TESTIMONIAL = "GET_TESTIMONIAL"
export const SET_TESTIMONIAL = "SET_TESTIMONIAL"
export const RESET_TESTIMONIAL = "RESET_TESTIMONIAL"

export const getTestimonials = () => (dispatch, getState) => {
    return getState().testimonials
}

export const setTestimonials = (payload) => {
    return {
        type: SET_TESTIMONIALS,
        payload
    }
}

export const resetTestimonials = () => {
    return {
        type: RESET_TESTIMONIALS
    }
}

export const getTestimonial = () => (dispatch, getState) => {
    return getState().testimonial
}

export const setTestimonial = (payload) => {
    return {
        type: SET_TESTIMONIAL,
        payload
    }
}

export const resetTestimonial = () => {
    return {
        type: RESET_TESTIMONIAL
    }
}

export const fetchTestimonials = () => async (dispatch, getState) => {
    try {
        const axios = require("./axios")();
        const {status, data} = await axios.get("/testimonials", {
            params: {
                Approved: true,
            }
        })
        if (status < 200 && status >= 300) {
            return false
        }
        return dispatch(setTestimonials(data))
    } catch (error) {
        return false
    }
}

export const fetchTestimonial = (id) => (dispatch, getState) => {
    if (!id) {
        return
    }
    const axios = require("./axios")
    axios.get(`/testimonials/${id}`)
        .then(({
            status,
            data
        }) => {
            if (status === 200) {
                dispatch(setTestimonial(data))
            }
        })
}