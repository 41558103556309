import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    asyncValidateForgetPassword,
    validateForgetPassword
} from "../../validate";

import { forgetPassword } from "../../actions/auth";

const renderField = props => {
    const {
        input,
        label,
        type,
        meta: { touched, error }
    } = props;
    const classes = ["form-group"];
    return (
        <div
            className={
                touched && error
                    ? classes.concat(["has-error", "has-feedback"]).join(" ")
                    : classes.join(" ")
            }
        >
            <label className="control-label sr-only">{label}</label>
            <input
                className="form-control"
                {...input}
                placeholder={label}
                type={type}
                aria-describedby={`${label}Status`}
            />
            {touched && error && (
                <span
                    className="glyphicon glyphicon-remove form-control-feedback"
                    aria-hidden="true"
                />
            )}
            {touched && error && (
                <small id={`${label}Status`} style={{ color: "Red" }}>
                    {error}
                </small>
            )}
        </div>
    );
};

class ForgetPasswordModal extends Component {
    render() {
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div
                    className="modal fade forgetPassword"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myForgotPasswordModal"
                >
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <div
                                    className="for-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <img
                                        src={`${
                                            process.env.PUBLIC_URL
                                        }assets/images/icon/close-tab.png`}
                                        alt=""
                                    />
                                </div>
                                <img
                                    src={`${
                                        process.env.PUBLIC_URL
                                    }assets/images/logo/mg-logo.png`}
                                    alt=""
                                />
                            </div>
                            <div className="modal-body text-center">
                                <p className="title">
                                    {
                                        this.props.lang[this.props.lang.locales]
                                            .contents.forgetPassword.modal
                                            .heading
                                    }
                                </p>
                                <p className="text-content">
                                    {
                                        this.props.lang[this.props.lang.locales]
                                            .contents.forgetPassword.modal
                                            .content
                                    }
                                </p>
                                <Field
                                    component={renderField}
                                    type="email"
                                    name="Email"
                                    className="form-control"
                                    label="Email"
                                />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="submit"
                                    className="btn button-reset"
                                    disabled={
                                        this.props.pristine ||
                                        this.props.submitting ||
                                        this.props.error
                                    }
                                >
                                    {
                                        this.props.lang[this.props.lang.locales]
                                            .contents.forgetPassword.modal
                                            .button
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

const mapDispatchToProps = (dispatch, { history }) => {
    return {
        onSubmit: values =>
            dispatch(
                forgetPassword({
                    ...values,
                    history
                })
            )
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "forgetPassword",
            validate: validateForgetPassword,
            asyncValidate: asyncValidateForgetPassword,
            asyncChangeFields: ["Email"]
        })(ForgetPasswordModal)
    )
);
