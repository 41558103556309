import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCustomPage, resetPage } from "../../../actions/pages";

import MainPage from "../MainPage";

class MgTableTopPage extends Component {
    componentWillMount() {
        const {
            match: { path },
            history
        } = this.props;
        this.props.fetchCustomPage({
            path,
            history
        });
    }

    componentWillUnmount() {
        this.props.resetPage();
    }
    render() {
        console.log(this.props);

        return (
            <MainPage>
                <section className="banner-landingpage">
                    <div
                        className="backgroundImage"
                        style={{
                            backgroundImage: `url(${this.props.page.Image})`
                        }}
                    />
                </section>

                <section
                    className="banner-second-landing-page-mgtt"
                    style={{
                        backgroundImage:
                            "url(../assets/images/background/background-blue-new.jpg)"
                    }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="placeandtime-info">
                                    <div className="title-mgtt-mega">
                                        <h2>MG Table Top August 2019</h2>
                                    </div>
                                    <div className="placetime-mgtt-mega">
                                        <div className="row text-center">
                                            <div className="col-xs-4">
                                                <div className="time">
                                                    <ul className="divider-point">
                                                        <div className="for-absolute">
                                                            Medan
                                                        </div>
                                                        <li className="border">
                                                            <div className="date">
                                                                <p
                                                                    className="date1"
                                                                    style={{
                                                                        marginTop:
                                                                            "10px"
                                                                    }}
                                                                >
                                                                    9
                                                                </p>
                                                                <p className="date2">
                                                                    August
                                                                    <br />
                                                                    2019
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="clock">
                                                                <p className="satu">
                                                                    14:00
                                                                </p>
                                                                <p className="dua">
                                                                    until
                                                                </p>
                                                                <p className="tiga">
                                                                    17:00
                                                                </p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-xs-8">
                                                <div className="place">
                                                    <div className="imageplace">
                                                        <img
                                                            className="display: block;"
                                                            src={`${
                                                                process.env
                                                                    .PUBLIC_URL
                                                            }/assets/images/content/logo-aryaduta.png`}
                                                            width="349"
                                                            height="40"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="positionplace">
                                                        Aryaduta Medan Hotel
                                                        @Lotus 1 Ballroom
                                                    </div>
                                                    <div className="activity">
                                                        Jl. Kapten Maulana
                                                        Lubis, Medan Petisah,
                                                        <br />
                                                        Sumatera Utara
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="placetime-mgtt-mega">
                                        <div className="row text-center">
                                            <div className="col-xs-4">
                                                <div className="time">
                                                    <ul className="divider-point">
                                                        <div className="for-absolute">
                                                            Jakarta
                                                        </div>
                                                        <li className="border">
                                                            <div className="date">
                                                                <p
                                                                    className="date1"
                                                                    style={{
                                                                        marginTop:
                                                                            "10px"
                                                                    }}
                                                                >
                                                                    21
                                                                </p>
                                                                <p className="date2">
                                                                    August
                                                                    <br />
                                                                    2019
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="clock">
                                                                <p className="satu">
                                                                    09:00
                                                                </p>
                                                                <p className="dua">
                                                                    until
                                                                </p>
                                                                <p className="tiga">
                                                                    17:00
                                                                </p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-xs-8">
                                                <div className="place">
                                                    <div className="imageplace">
                                                        <img
                                                            className="display: block;"
                                                            src={`${
                                                                process.env
                                                                    .PUBLIC_URL
                                                            }/assets/images/content/logo-holiday-inn.png`}
                                                            width=""
                                                            height="75"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div
                                                        className="positionplace"
                                                        style={{
                                                            fontSize: "18px"
                                                        }}
                                                    >
                                                        Holiday Inn &amp; Suites
                                                        Jakarta Gajah Mada
                                                        @Harmoni Ballroom
                                                    </div>
                                                    <div className="activity">
                                                        Jl. Gajah Mada No.211
                                                        Taman Sari, West Jakarta
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="placetime-mgtt-mega">
                                        <div className="row text-center">
                                            <div className="col-xs-4">
                                                <div className="time">
                                                    <ul className="divider-point">
                                                        <div className="for-absolute">
                                                            Bandung
                                                        </div>
                                                        <li className="border">
                                                            <div className="date">
                                                                <p
                                                                    className="date1"
                                                                    style={{
                                                                        marginTop:
                                                                            "10px"
                                                                    }}
                                                                >
                                                                    23
                                                                </p>
                                                                <p className="date2">
                                                                    August
                                                                    <br />
                                                                    2019
                                                                </p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="clock">
                                                                <p className="satu">
                                                                    09:00
                                                                </p>
                                                                <p className="dua">
                                                                    until
                                                                </p>
                                                                <p className="tiga">
                                                                    17:00
                                                                </p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-xs-8">
                                                <div className="place">
                                                    <div className="imageplace">
                                                        <img
                                                            className="display: block;"
                                                            src={`${
                                                                process.env
                                                                    .PUBLIC_URL
                                                            }/assets/images/content/logo-aryaduta.png`}
                                                            width="349"
                                                            height="40"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="positionplace">
                                                        Aryaduta Hotel Bandung
                                                        @The Ballroom
                                                    </div>
                                                    <div className="activity">
                                                        Jl. Sumatera No.51,
                                                        Citarum Bandung, <br />
                                                        West Java
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="regis-prize-card">
                                    <div className="row">
                                        <div className="col-xs-4 text-center">
                                            <img
                                                style={{
                                                    display: "inline-block"
                                                }}
                                                src={`${
                                                    process.env.PUBLIC_URL
                                                }../assets/images/icon/pin-registration.png`}
                                                alt=""
                                                width="120"
                                                height="120"
                                            />
                                            <div className="nametitlecode">
                                                <div className="title">
                                                    <p>Buyer Registration</p>
                                                </div>
                                                <div className="anotherinfo">
                                                    <p>
                                                        13:30 for Medan Table
                                                        Top <br />
                                                        08:30 for Jakarta &amp;
                                                        Bandung Table Top
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-4 text-center">
                                            <img
                                                style={{
                                                    display: "inline-block"
                                                }}
                                                src={`${
                                                    process.env.PUBLIC_URL
                                                }../assets/images/icon/pin-doorprize.png`}
                                                alt=""
                                                width="120"
                                                height="120"
                                            />
                                            <div className="nametitlecode">
                                                <div className="title">
                                                    <p>Door Prize</p>
                                                </div>
                                                <div className="anotherinfo">
                                                    <p>
                                                        Shopping Voucher IDR 5
                                                        Million (Medan) <br />
                                                        LED TV 40” &amp;
                                                        Shopping Voucher
                                                        (Jakarta &amp; Bandung)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-4 text-center">
                                            <img
                                                style={{
                                                    display: "inline-block"
                                                }}
                                                src={`${
                                                    process.env.PUBLIC_URL
                                                }../assets/images/icon/pin-namecard.png`}
                                                alt=""
                                                width="120"
                                                height="120"
                                            />
                                            <div className="nametitlecode">
                                                <div className="title">
                                                    <p>Please Bring</p>
                                                </div>
                                                <div className="anotherinfo">
                                                    <p>
                                                        Min. 100 pcs name card
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="button-rsvp-mgtt">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-xs-12 text-center">
                                <h2>RSVP</h2>
                                <div className="email-to-registration">
                                    <div
                                        className="box-email-to-registration"
                                        style={{ marginBottom: "10px" }}
                                    >
                                        <a
                                            href="mailto:indah.putri@mgholiday.com"
                                            style={{
                                                color: "#004268",
                                                fontSize: "20px"
                                            }}
                                        >
                                            indah.putri@mgholiday.com
                                        </a>
                                        <p style={{ margin: 0 }}>
                                            Table Top Medan
                                        </p>
                                    </div>
                                    <br />
                                    <p>or</p>
                                    <div
                                        className="box-email-to-registration"
                                        style={{ marginTop: "5px" }}
                                    >
                                        <a
                                            href="mailto:kristin.eka@mgholiday.com"
                                            style={{
                                                color: "#004268",
                                                fontSize: "20px"
                                            }}
                                        >
                                            kristin.eka@mgholiday.com
                                        </a>
                                        <p style={{ margin: 0 }}>
                                            Table Top Medan, Jakarta, Bandung
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </MainPage>
        );
    }
}
const mapStateToProps = state => {
    return {
        page: state.page
    };
};

const mapDispatchToProps = (dispatch, ownProp) => {
    return {
        fetchCustomPage: payload => dispatch(fetchCustomPage(payload)),
        resetPage: () => dispatch(resetPage())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MgTableTopPage);
