// import * as components from '../components/index.js'
// import { Home } from '../containers'
import { AboutPage, AmazingRacePage, ContactPage, HomePage, JoinPage, LandingPage, PointsExchangeProcedurePage, ProfilePage, PromotionPage, RedemptionPage, ResetPasswordPage, RewardPage, TransactionPage, MgTableTopPage, PrivacyPolicy } from '../components/pages';

let routes = [{
        path: "/",
        exact: true,
        component: HomePage
    },
    {
        path: "/about",
        exact: true,
        component: AboutPage,
    },
    {
        path: "/about-us",
        exact: true,
        component: AboutPage,
    },
    {
        path: "/join",
        exact: true,
        component: JoinPage,
    },
    {
        path: "/how-to-join",
        exact: true,
        component: JoinPage,
    },
    {
        path: "/contact",
        exact: true,
        component: ContactPage,
    },
    {
        path: "/contact-us",
        exact: true,
        component: ContactPage,
    },
    {
        path: "/privacy-policy",
        exact: true,
        component: PrivacyPolicy,
    },
    // {
    //     path: "/login",
    //     exact: true,
    //     component: components.Login
    // },
    // {
    //     path: "/signup",
    //     exact: true,
    //     component: components.SignUp
    // },
    // {
    //     path: "/landingpage",
    //     exact: true,
    //     component: LandingPage,
    // },
    {
        path: "/landingpage/:slug",
        exact: true,
        component: LandingPage,
    },
    {
        path: "/page/:slug",
        exact: true,
        component: LandingPage,
    },
    {
        path: "/rewards",
        exact: true,
        component: RewardPage,
        isLogin: true,
    },
    {
        path: "/transactions",
        exact: true,
        component: TransactionPage,
        isLogin: true,
    },
    {
        path: "/promotions",
        exact: true,
        component: PromotionPage,
        isLogin: true,
    },
    // {
    //     path: "/hotel-promotion",
    //     exact: true,
    //     component: components.HotelPromotion,
    //     isLogin: true,
    // },
    // {
    //     path: "/hotel-promotion-2",
    //     exact: true,
    //     component: components.HotelPromotion2,
    //     isLogin: true,
    // },
    // {
    //     path: "/claim-rewards",
    //     exact: true,
    //     component: components.ClaimRewards,
    //     isLogin: true,
    // },
    {
        path: "/redemptions",
        exact: true,
        component: RedemptionPage,
        isLogin: true,
    },
    {
        path: "/profile",
        exact: true,
        component: ProfilePage,
        isLogin: true,
    },
    {
        path: "/points-exchange-procedure",
        exact: true,
        component: PointsExchangeProcedurePage,
    },
    {
        path: "/reset-password/:token",
        exact: true,
        component: ResetPasswordPage,
    },
    {
        path: "/reset-password-2/:token",
        exact: true,
        component: ResetPasswordPage,
    },
    {
        path: "/password-reset/:token",
        exact: true,
        component: ResetPasswordPage,
    },
    {
        exact: true,
        path: "/amazing-race",
        component: AmazingRacePage,
    },
    {
        exact: true,
        path: "/mgtabletop",
        component: MgTableTopPage,
    },
    {
        exact: true,
        path: "/:slug",
        component: LandingPage,
    },
    
];

export default routes