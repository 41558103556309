import React from "react";
import { connect } from "react-redux";
import GuestHomePage from "./GuestHomePage";
import RewardPage from "./RewardPage";

const Home = ({ isLogin }) => (isLogin ? <RewardPage /> : <GuestHomePage />);

const mapStateToProps = state => {
    return {
        isLogin: state.account.isLogin
    };
};

export default connect(mapStateToProps)(Home);
