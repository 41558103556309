export const GET_TRANSACTIONS = "GET_TRANSACTIONS"
export const SET_TRANSACTIONS = "SET_TRANSACTIONS"
export const SET_TRANSACTIONS_PAGINATION = "SET_TRANSACTIONS_PAGINATION"
export const RESET_TRANSACTIONS = "RESET_TRANSACTIONS"

export const GET_TRANSACTION = "GET_TRANSACTION"
export const SET_TRANSACTION = "SET_TRANSACTION"
export const RESET_TRANSACTION = "RESET_TRANSACTION"

export const getTransactions = () => (dispatch, getState) => {
    return getState().transactions.rows
}

export const setTransactions = (payload) => {
    return {
        type: SET_TRANSACTIONS,
        payload
    }
}

export const resetTransactions = () => {
    return {
        type: RESET_TRANSACTIONS
    }
}

export const getTransaction = () => (dispatch, getState) => {
    return getState().transaction
}

export const setTransaction = (payload) => {
    return {
        type: SET_TRANSACTION,
        payload
    }
}

export const setTransactionsPagination = payload => {
    return {
        type: SET_TRANSACTIONS_PAGINATION,
        payload
    }
}

export const resetTransaction = () => {
    return {
        type: RESET_TRANSACTION
    }
}

export const fetchTransactions = () => (dispatch, getState) => {
    const axios = require("./axios")()
    const {
        page,
        perPage
    } = getState().transactions.pagination
    axios.get("/transactions", {
            params: {
                page,
                perPage
            }
        })
        .then(({
            status,
            headers,
            data: rows
        }) => {
            if (status === 200) {
                return dispatch(setTransactions({
                    count: headers["x-total-count"],
                    rows
                }))
            }
        })
    return
}

export const fetchTransasction = (id) => (dispatch, getState) => {
    if (!id) {
        return
    }
    const axios = require("./axios")()
    axios.get(`/transactions/${id}`)
        .then(({
            status,
            data
        }) => {
            if (status === 200) {
                dispatch(setTransaction(data))
            }
        })
}