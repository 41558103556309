import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const BannerPromotionSection = ({ lang }) => (
    <section className="promo-hotel">
        <div className="container">
            <div className="row">
                <div className="col-xs-12">
                    <div className="box text-center">
                        <Link to="/promotions">
                            <img
                                src={`${
                                    process.env.PUBLIC_URL
                                }/assets/images/content/${
                                    lang.locales === "en"
                                        ? "promo-hotel-eng"
                                        : "promo-hotel"
                                }.jpg`}
                                alt=""
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};
export default connect(mapStateToProps)(BannerPromotionSection);
