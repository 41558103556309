import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Logout from "./Logout";

const Avatar = ({ user: { Image, Name, Genius }, lang }) => (
    <div className="profile-login">
        <div className="img-profile">
            <img
                src={Image}
                alt=""
                className="img-circle"
                style={{ width: 40, height: 40 }}
            />
        </div>
        <div className="body-profile">
            <h4 className="name">{Name}</h4>
            <p className="status">{Genius}</p>
            <p className="edit-logout">
                <Link to="/profile">
                    {lang[lang.locales].navigations.editProfile}
                </Link>
                | <Logout lang={lang} />
            </p>
        </div>
    </div>
);

const mapStateToProps = state => {
    const { Image, Name, GeniusMember } = state.account;
    return {
        user: {
            Image,
            Name,
            Genius: Boolean(GeniusMember) ? "Genius Member" : "Silver Member"
        },
        lang: state.lang
    };
};

export default connect(mapStateToProps)(Avatar);
