import { SET_LOCALES } from '../actions';
import lang from '../languages';

const initialState = {
    locales: 'en',
    ...lang,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_LOCALES:
            return {
                ...state,
                ...payload,
            };
    
        default:
            return state;
    }
};