const en = {
    actions: {
        save: "Save",
        login: "Login",
        signin: "Login",
        signup: "Register",
        register: "Register",
        send: "Send",
        follow: "Follow MG Friends social media",
        claim: "Claim",
        redeem: "Redeem",
        forget: "Forget Password?"
    },
    navigations: {
        home: "Home",
        transactions: "Transactions",
        transaction: "Transactions",
        rewards: "Rewards",
        reward: "Rewards",
        login: "Sign In",
        signin: "Sign In",
        profile: "Profile",
        logout: "Logout",
        signout: "Logout",
        about: "About MG Friends",
        aboutUs: "About MG Friends",
        howToJoin: "How to Join",
        join: "How to Join",
        contactUs: "Contact Us",
        contact: "Contact Us",
        signup: "Register",
        register: "Register",
        redemptions: "Claim Reward Info",
        redemption: "Claim Reward Info",
        promotions: "Hotel Promotions",
        promotion: "Hotel Promotions",
        editProfile: "Edit Profile",
        privacyPolicy: "Privacy Policy"
    },
    headings: {
        about: "About MG Friends",
        howToJoin: "How to Join MG Friends?",
        join: "How to Join MG Friends?",
        testimonial: "Agents Testimonial",
        termCondition: "Terms and Condition",
        redemptionProcedure: "Redemption Procedure",
        hotelPromotion: "Hotel Promotion",
        transaction: "Booking History",
        claim: "Claim Reward Info"
    },
    misc: {
        building: "MG Group",
        address: "Majapahit Street No. 16, Central Jakarta 10160, Indonesia",
        phone: "(62 21) 2922 3933 ext 1123",
        fax: "(62 21) 2922 3999",
        mail: "admin@mgfriends.com",
        point: "Points",
        table: {
            page: "Report per Page",
            show: "Showing {start} to {end} of {count} entries",
            previous: "Previous",
            next: "Next"
        }
    },
    notifications: {
        signup: {
            success: "Signup success, please wait for approval. Thank you.",
            error: "",
            warning: "",
            info: "",
            question: ""
        },
        changeProfile: {
            success: "Your profile is update. Thank you.",
            error: "",
            warning: "",
            info: "",
            question: ""
        },
        changePassword: {
            success: "Your password is update. Thank you.",
            error: "",
            warning: "",
            info: "",
            question: ""
        },
        changeAgent: {
            success:
                "Change Agent success, please wait for approval. Thank you.",
            error: "",
            warning: "",
            info: "",
            question: ""
        },
        forgetPassword: {
            success: "Reset Password success, check your email. Thank you",
            error: "",
            warning: "",
            info: "",
            question: ""
        },
        contact: {
            success: "Thank you for your email",
            error: "Try Again",
            warning: "",
            info: "",
            question: ""
        }
    },
    validations: {},
    images: {},
    forms: {
        signup: {
            agentCode: "MG bedbank Agent ID ",
            userId: "MG bedbank Username",
            phone: "Phone Number",
            email: "Email",
            password: "Password",
            passwordConfirm: "Re-Enter Password"
        },
        login: {
            email: "Email",
            password: "Password"
        },
        contact: {
            name: "Name",
            email: "Email",
            phone: "Phone Number",
            office: "Office",
            subject: "Subject",
            captcha: "Press recaptcha button first"
        },
        changeProfile: {
            agent: "Agent ID",
            garudaMiles: "Garuda Miles ID",
            email: "Email",
            name: "Name",
            bankName: "Bank Name",
            bankAccount: "Bank Account",
            bankAccountName: "Bank Account Name",
            phone: "Phone Number",
            address: "Address",
            city: "City",
            birthDate: "Birth Date",
            picture: "Profile Picture",
            chooseFile: "Choose File",
            noChooseFile: "No File Chosen",
            save: "Save"
        },
        changePassword: {
            password: "Current Password",
            newPassword: "New Password",
            newPasswordConfirm: "Re-Enter New Password",
            save: "Save"
        },
        changeAgent: {
            currentAgent: "Current Agent Code",
            currentUser: "Current MG User ID",
            newAgent: "New Agent Code",
            noteAgent: "If not replaced entries with old agent code",
            newUser: "New MG User ID",
            noteUser: "If not replaced entries with old mg user id",
            save: "Save"
        }
    },
    contents: {
        privacyPolicy: {
            subheading:
                "Privacy Policy",
            body: 
                `
                    <p>At MG Friends , we are committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, store, and disclose personal information when you use our loyalty program app ("MG Friends ").</p>
                    <h3>Information We Collect</h3>
                    <p>1.1 Personal Information: We may collect personal information such as your name, email address, phone number, and demographic information when you sign up for our loyalty program.</p>
                    <p>1.2 Loyalty Program Data: We collect information about your transactions, purchases, rewards, and other interactions with the loyalty program.</p>
                    <p>1.3 Device Information: We may collect device-specific information, such as your device type, operating system, and unique device identifiers.</p>
                    <h3>Use Of Information</h3>
                    <p>2.1 Personalization: We use your personal information to personalize your loyalty program experience, including providing relevant offers, rewards, and recommendations.</p>
                    <p>2.2 Communication: We may use your contact information to send you notifications, updates, and marketing materials related to the MG Friends loyalty program. You can opt out of receiving promotional communications.</p>
                    <p>2.3 Analytics: We use aggregated and anonymized data to analyze usage patterns, improve our App, and enhance our loyalty program. </p>
                    <h3>Sharing of Information</h3>
                    <p>3.1 Service Providers: We may share your information with trusted third-party service providers who assist us in operating and improving our App and loyalty program.</p>
                    <p>3.2 Legal Compliance: We may disclose your information if required by law or in response to a valid legal request.</p>
                    <h3>Data Security</h3>
                    <p>4.1 Security Measures: We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, loss, or alteration.</p>
                    <p>4.2 Data Retention: We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required by law.</p>
                    <h3>Third-Party Links</h3>
                    <p>Our App may contain links to third-party websites or services. We are not responsible for the privacy practices or content of those websites. We encourage you to review the privacy policies of any third-party websites you visit.</p>
                    <h3>Children's Privacy</h3>
                    <p>Our App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children. If you believe we have inadvertently collected information from a child, please contact us to have it removed.</p>
                    <h3>Changes to this Privacy Policy</h3>
                    <p>We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any material changes through the App or other communication channels. Your continued use of the App after the changes indicates your acceptance of the revised Privacy Policy.</p>
                    <h3>Contact Us</h3>
                    <p>If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us at <a href="mailto:support@mgfriends.com">support@mgfriends.com</a></p>
                `,
        },
        about: {
            subheading:
                "Are you a MG bedbank agent? It's time to join MG Friends as well! MG Friends is a friendship program between MG and you, who always trust MG bedbank as your hotel booking partner.",
            body:
                'Book hotels via <a href="https://mgbedbank.com" target="_blank"><span>mgbedbank.com</span></a>, and you will get <span>1 MGF Points</span> for every 1 room night.<br /><br />Collect your points and redeem to <span>cash vouchers</span>, <span>shopping vouchers</span>, <span>hotel vouchers</span> and <span>various of interesting products</span>.<br /><br />MGF points will be stored into your account and recorded in the transaction report, 5 (five) days after the guest check-out date. So, book more hotels through MG bedbank. Best price guaranted, numerous hotel choices all over the world, get your points and get more PRIZES.<br /><br />Dont forget to register yourself with the same Agent ID and User ID for booking hotels in <a href="https://mgbedbank.com" target="_blank"><span>mgbedbank.com</span></a>.'
        },
        join: {
            subheading: "How to Join",
            body:
                "<div class='col-xs-3 text-center'><p>Visit mgfriends.com,<br />click 'REGISTER' on the right side</p></div><div class='col-xs-3 text-center'><p>Fill the registration form<br />and click 'REGISTER'</p></div><div class='col-xs-3 text-center'><p>Don't forget to register yourself<br />with the same Agent ID and<br />User ID for booking hotels on<br />mgbedbank.com</p></div><div class='col-xs-3 text-center'><p>Our team will<br />send the confirmation email<br />once you are<br />verified</p></div>"
        },
        termCondition: {
            body: {
                list1: "The prize list might be modified without prior notice",
                list2:
                    "Point redemption only valid for each individual agent. Your point can't be combined with other agent's point",
                list3:
                    "Please make sure to re-confirm to our team prior to point redemption, as we need to check the product availability",
                list4: "For more details, please refer to ",
                link: "redemption procedure"
            }
        },
        redemptionProcedure: {
            subheading: {
                cash: "Cash Voucher",
                voucher: "Shopping and Hotel Voucher",
                product: "Products"
            },
            body: {
                cash:
                    "<li>Make sure that you have registered the correct and active bank account number in your profile</li><li>Make sure that your points are enough to redeem your selected voucher</li><li>Select your voucher and you will receive a confirmation email</li><li>You will receive another confirmation email once your request has been approved and is in process</li><li>The money will be transferred to your bank account in -/+ 30 days after the approval date</li>",
                voucher:
                    "<li>Make sure that you have registered the correct and valid address in your profile</li><li>Make sure that your points are enough to redeem your selected voucher</li><li>Select your voucher and you will receive a confirmation email</li><li>You will receive another confirmation email once your request has been approved and is in process</li><li>You will receive the voucher in -/+ 14 days after the approval date</li><li>The voucher T&C follows on what is stated in the voucher and exclusively based on the company rules</li><li>Voucher is not replaceable if lost, damaged or stolen</li>",
                product:
                    "<li>Make sure that you have registered the correct and valid address in your profile</li><li>Make sure that your points are enough to redeem your selected voucher</li><li>Please make sure to re-confirm to our team prior to point redemption, as we need to check the product availability</li><li>Select your voucher and you will receive a confirmation email</li><li>You will receive another confirmation email once your request has been approved and is in process</li><li>You will receive the product in -/+ 14 days after the approval date</li>",
                back: "Back to reward page"
            }
        },
        contact: {
            subheading: "Contact Us",
            address:
                "Head Office<br /> Majapahit Street No. 16, <br /> Central Jakarta 10160, Indonesia"
        },
        reward: {
            subheading: "Reward List Redemption",
            statusBox: {
                point: "Your Point",
                status: "Your Status"
            },
            modal: {
                subheading: "Reward",
                agent: "Agent Name",
                userId: "MG User ID",
                note: "Your points will be reduced by",
                action: "Claim Reward"
            }
        },
        hotelPromotion: {
            subheading:
                "Hotel Promotion <br /> and MG Friends <br /> Bonus Points",
            termCondition:
                "Terms & Condition<div><ul><li class='text-tac'>Above list is hotel that have MGF Xtrapoint</li><li class='text-tac'>You will get the xtrapoint based on check-out period</li><li class='text-tac'>You will be able to earn MGF point for all hotel booking <a href='https://www.mgbedbank.com/'><span>mgbedbank.com</span></a></li></ul></div>"
        },
        claim: {
            pointInfo: {
                tab: "Redemption Info",
                table: {
                    date: "Date",
                    description: "Description",
                    account: "Account",
                    amount: "Amount",
                    balance: "Balance"
                }
            },
            redeemInfo: {
                tab: "Claimed Detail Info",
                table: {
                    date: "Date",
                    reward: "Reward",
                    value: "Value",
                    point: "Point",
                    status: "Status"
                }
            }
        },
        transaction: {
            table: {
                hotelName: "Hotel Name",
                city: "City",
                country: "Country",
                bookingDate: "Booking Date",
                checkIn: "Check In",
                checkOut: "Check Out",
                room: "Room",
                night: "Night",
                points: "Points",
                source: "Source"
            }
        },
        forgetPassword: {
            modal: {
                heading: "Reset Password",
                content: "Insert your personal email here",
                button: "Reset Password"
            }
        }
    },
    modals: {
        signup: {
            headings: {
                one: "Let's register to be MG Friends member",
            },
        },
    },
};

export default en;
