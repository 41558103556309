module.exports = () => {
    const axios = require("axios");
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common["X-API-KEY"] = process.env.REACT_APP_API_KEY;

    const token = localStorage.getItem("token");
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    return axios;
}