import {
    SET_ACCOUNT_STATEMENT,
    RESET_ACCOUNT_STATEMENT
} from "../actions/accountStatements"

const initialState = {}

export default (state = initialState, {
    type,
    payload
}) => {
    switch (type) {
        case SET_ACCOUNT_STATEMENT:
            return {
                ...state,
                ...payload
            }
        case RESET_ACCOUNT_STATEMENT:
            return initialState
        default:
            return state
    }
}