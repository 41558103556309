import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const TermConditionSection = ({ lang }) => {
    return (
        <section className="syarat-ketentuan">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 text-center">
                        <div className="title">
                            {lang[lang.locales].headings.termCondition}
                        </div>
                        <div>
                            <ol>
                                <li className="text">
                                    {
                                        lang[lang.locales].contents
                                            .termCondition.body.list1
                                    }
                                </li>
                                <li className="text">
                                    {
                                        lang[lang.locales].contents
                                            .termCondition.body.list2
                                    }
                                </li>
                                <li className="text">
                                    {
                                        lang[lang.locales].contents
                                            .termCondition.body.list3
                                    }
                                </li>
                                <li className="text">
                                    {
                                        lang[lang.locales].contents
                                            .termCondition.body.list4
                                    }
                                    <span>
                                        <Link to="/points-exchange-procedure">
                                            {
                                                lang[lang.locales].contents
                                                    .termCondition.body.link
                                            }
                                        </Link>
                                    </span>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

export default connect(mapStateToProps)(TermConditionSection);
