import React from "react";
import { connect } from "react-redux";
import ClaimButton from "./ClaimButton";

const claimMessage = ({
  Address,
  ClaimBankAccount,
  ClaimBankHolder,
  ClaimBankName,
  ClaimGarudaMilesID,
  Phone,
  ClaimTokopediaEmail,
  ClaimOvoPhone,
  Name,
  ClaimMapId,
  ClaimMapPhone,
  ClaimMapName,
  ...member
}) => ({ Category, ...reward }) => {
  const Points = member.GeniusMember
    ? reward.Points - reward.PointsDiscount
    : reward.Points;
  if (member.Point < Points) {
    return `You do not have enough points to claim this reward.`;
  }
  switch (Category) {
    case "HOLIDAY":
    case "HOTEL":
    case "PRODUCT":
    case "VOUCHER":
      return Name === "null" || Address === "null" || Phone === "null"
        ? `Please complete your contact information!`
        : true;
    case "CASH":
      return Name == null ||
        Address == null ||
        Phone == null ||
        ClaimBankName === "null" ||
        ClaimBankAccount === "null" ||
        ClaimBankHolder === "null"
        ? `Please complete your contact and bank information!`
        : true;
    case "GARUDA":
      return !Name || !Address || !Phone || ClaimGarudaMilesID === "null"
        ? `Please complete your contact information and Garuda Miles ID!`
        : true;
    case "TOKOPEDIA":
      return !Name || !Address || !Phone || ClaimTokopediaEmail === "null"
        ? `Please complete your contact information and Tokopedia Email!`
        : true;
    case "OVO":
      return !Name || !Address || !Phone || ClaimOvoPhone === "null"
        ? `Please complete your contact information and Ovo Phone Number!`
        : true;
    case "MAP":
      return !Name ||
        !Address ||
        !Phone ||
        ClaimMapId === "null" ||
        ClaimMapPhone === "null" ||
        ClaimMapName === "null"
        ? `Please complete your contact information, MAP ID, MAP Full Name and MAP Phone Number!`
        : true;
    default:
      return true;
  }
};

const Modal = ({ history, reward, account, onClaim, lang }) => {
  const claimable = claimMessage(account)(reward);
  return (
    <div
      className="modal fade rewardModalCashVoucher"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="rewardCashVoucher"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <div className="for-close" data-dismiss="modal" aria-label="Close">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icon/close-tab.png`}
                alt="close"
              />
            </div>
            <h3 className="modal-title">
              {lang[lang.locales].contents.reward.modal.subheading}
            </h3>
            <hr />
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-xs-4">
                <div className="rewardModalPictureVoucher">
                  <img
                    src={reward.Image}
                    alt={reward.Name}
                    style={{ height: 220 }}
                  />
                </div>
              </div>
              <div className="col-xs-8">
                <h3>{reward.Name}</h3>
                <div className="rewardAgentName">
                  <div className="tag-first">
                    {lang[lang.locales].contents.reward.modal.agent}
                  </div>
                  <div className="tag-second">{account.AgentCode}</div>
                </div>
                <br />
                <div className="rewardAgentID">
                  <div className="tag-first">
                    {lang[lang.locales].contents.reward.modal.userId}
                  </div>
                  <div className="tag-second">{account.UserId}</div>
                  <div className="tag-third">
                    {account.GeniusMember ? `Genius Member` : `Silver Member`}
                  </div>
                </div>
                <br />
                {reward.Category === "CASH" && (
                  <div className="voucherExchangePoint">
                    <div className="voucher-normal">
                      <p>
                        {new Intl.NumberFormat("en-US").format(reward.Value)}
                      </p>
                    </div>
                    {account.GeniusMember && reward.BonusValue > 0 && (
                      <div className="voucher-extra">
                        <div className="plus">
                          <p>+</p>
                        </div>
                        <div className="extra">
                          <p className="extra-1">Genius Extra</p>
                          <p className="extra-2">
                            {new Intl.NumberFormat("en-US").format(
                              reward.BonusValue
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="voucher-total">
                      <p className="header">Total</p>
                      <p className="point">
                        {new Intl.NumberFormat("en-US").format(
                          account.GeniusMember
                            ? reward.Value + reward.BonusValue
                            : reward.Value
                        )}
                      </p>
                    </div>
                  </div>
                )}
                {reward.Category !== "CASH" && (
                  <div className="voucherExchangePoint">
                    <div className="voucher-normal">
                      <p>{`${new Intl.NumberFormat("en-US").format(
                        reward.Points
                      )} ${lang[lang.locales].misc.point}`}</p>
                    </div>
                    {account.GeniusMember && reward.PointsDiscount > 0 && (
                      <div className="voucher-extra">
                        <div className="plus">
                          <p>-</p>
                        </div>
                        <div className="extra">
                          <p className="extra-1">Genius Extra</p>
                          <p className="extra-2">
                            {`${new Intl.NumberFormat("en-US").format(
                              reward.PointsDiscount
                            )} ${lang[lang.locales].misc.point} `}
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="voucher-total">
                      <p className="header">Total</p>
                      <p className="point">
                        {`${new Intl.NumberFormat("en-US").format(
                          account.GeniusMember
                            ? reward.Points - reward.PointsDiscount
                            : reward.Points
                        )} ${lang[lang.locales].misc.point}`}
                      </p>
                    </div>
                  </div>
                )}
                <p className="jumlahpoint">
                  {`${
                    lang[lang.locales].contents.reward.modal.note
                  } ${new Intl.NumberFormat("en-US").format(
                    account.GeniusMember
                      ? reward.Points - reward.PointsDiscount
                      : reward.Points
                  )} ${lang[lang.locales].misc.point}`}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <p
                  className="confirmation"
                  dangerouslySetInnerHTML={{
                    __html:
                      lang.locales === "id"
                        ? reward.Description
                        : reward.DescriptionEn
                  }}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {claimable === true ? (
              <ClaimButton />
            ) : (
              <p className="text-danger">{claimable}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account,
    reward: state.reward,
    lang: state.lang
  };
};

export default connect(mapStateToProps)(Modal);
