import { SET_SIGNUP, RESET_SIGNUP } from "../actions/signup"

const initialState = {}

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_SIGNUP:
            return {
                ...state,
                ...payload,
            }
        case RESET_SIGNUP:
            return initialState
        default:
            return state
    }
}