import React from 'react';
import { connect } from 'react-redux';
import MainPage from './MainPage';
import { fetchAmazing } from '../../actions';

const moment = require('moment');

class AmazingRace5Page extends React.Component {
    componentDidMount() {
        this.props.fetch();
    }

    render() {
        const {
            amazing
        } = this.props;
        let data = [];
        if (amazing.stages && amazing.requirements) {
            data = amazing.stages.map(stage => {
                return {
                    ...stage,
                    requirements: {
                        required: amazing.requirements.filter(({Stage, Required}) => Stage === stage.Stage && (Required === true || Required === 1)),
                        bonus: amazing.requirements.filter(({Stage, Required}) => Stage === stage.Stage && (Required === false || Required === 0)),
                    }
                };
            });
        }
        return (
            <MainPage title="Amazing Race 5">
                <section className="banner-landingpage">
                    <div className="backgroundImage" style={{
                        backgroundImage: `url(https://cdn.mgfriends.com/amazing/banner-amazing-race-5.jpg)`,
                    }} />
                </section>
                <section className="amazing-race-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="amazing-race-5-paths">
                                    <img src="https://cdn.mgfriends.com/amazing/banner-amazing-race-5-paths.png" alt="Amazing Race 5" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="description-landing-page-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <h1>Rules &amp; Regulation</h1>
                            </div>
                            <div className="col-xs-12">
                                <ul>
                                    <li>
                                        Valid for All MG Friends
                                    </li>
                                    <li>
                                        Check your current position by logging in through mgfriends.com and click "Amazing Race 5" tab
                                        menu on the top right page
                                    </li>
                                    <li>
                                        In each stage, you have to book hotels in particular destination with certain numbers of room
                                        nights to collect the points
                                    </li>
                                    <li>
                                        Complete all 4 stages by booking the hotels in each cities to get more points
                                    </li>
                                    <li>
                                        The bookings unnecessarily have to be in sequence. You can make bookings in Stage 2 or 4 first,
                                        then continue with the other stages. However, you are required to complete the previous Stage to
                                        collect all the points. For example, if Stage 2 is completed, you also need to complete Stage 1
                                        to get all the points.
                                    </li>
                                    <li>
                                        Bonus Mission is available in each stage. You can get additional points if you successfully book
                                        certain numbers of room nights for particular hotel (Don't forget to check the hotel list)
                                    </li>
                                    <li>
                                        Bonus Mission Points are earned if the Stage is completed
                                    </li>
                                    <li>
                                        All points collected in each stages will go directly to your MG Friends account after the 4
                                        Stages are completed
                                    </li>
                                    <li>
                                        After completing each stage, be noted to log-in at mgfriends.com and click the "Amazing Race 5"
                                        menu on the top right page to automatically add your points
                                    </li>
                                    <li>
                                        Make sure to log-in to your account frequently to check on your progress
                                    </li>
                                    <li>
                                        Bonus Mission Points are earned if the Stage is completed
                                    </li>
                                    <li>
                                        Room nights to be collected in each stage:
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="box-stage-amazing-race-5">
                    <div className="container">
                        <div className="row">
                            {
                                data.map(({Stage, Points, BonusPoints, requirements: {required, bonus}}, index) => (
                                    <div key={index} className="col-xs-6">
                                        <div className="box-stage">
                                            <h2>{`STAGE ${Stage}`}</h2>
                                            <div className="stage-total-point">
                                                <p>{`Total ${Points} Points`}</p>
                                            </div>
                                            <table>
                                                <tbody>
                                                    {
                                                        required.map(({Ref, RoomNight}, index) => (
                                                            <tr key={index}>
                                                                <td><i className="far fa-circle"></i></td>
                                                                <td>{Ref}</td>
                                                                <td>{`(Collect ${RoomNight} room nights at any hotels)`}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    {/* <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="total-room-nights">Total 150 room nights</td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                        {
                                            bonus.length > 0 &&
                                            (
                                                <div className="box-bonus">
                                                    <h2><i className="point-bullseye"></i>Bonus Mission</h2>
                                                    <p className="book-all">{`(Book all, get +${BonusPoints} points)`}</p>
                                                    {
                                                        bonus.map(({Ref, RoomNight}, index) => (
                                                            <p key={index}><i className="far fa-circle"></i>{`${Ref} ${RoomNight} room nights`}</p>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
                <section className="description-landing-page-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <h1>Terms &amp; Conditions</h1>
                            </div>
                            <div className="col-xs-12">
                                <ul>
                                    <li>
                                        {`Booking period and check in / out ${moment(amazing.Start).format('MMM, DD YYYY')} - ${moment(amazing.End).format('MMM, DD YYYY')}`}
                                    </li>
                                    <li>
                                        Amazing Race Points are not similar with Regular Points or MGF Xtra Points (if any)
                                    </li>
                                    <li>
                                        Amazing Race Points are only apply for MG Friends Members
                                    </li>
                                    <li>
                                        Haven't registered yet? Go to mgfriends.com and join us!
                                    </li>
                                    <li>
                                        All 3 winners who completed all stage will be announced in MG Social Media and Newsletter
                                    </li>
                                    <li>
                                        More info, click mgfriends.com/amazing-race
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        amazing: state.amazing,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetch: () => dispatch(fetchAmazing()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AmazingRace5Page);