import React, { Component } from "react";
import { connect } from "react-redux";
import EditUserProfile from "../../containers/EditUserProfile";
import EditUserAgent from "../../containers/EditUserAgent";
import EditUserPassword from "../../containers/EditUserPassword";

import MainPage from './MainPage';

class Profile extends Component {
    render() {
        return (
            <MainPage>
                <section className="content--editprofile">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="title-editprofile">
                                    <p>
                                        {
                                            this.props.lang[this.props.lang.locales]
                                                .navigations.editProfile
                                        }
                                    </p>
                                </div>
                                <div className="form-editprofile">
                                    <div className="form-ep">
                                        <div className="row">
                                            <EditUserProfile />
                                            <div className="col-xs-6">
                                                <EditUserAgent />
                                                <hr />
                                                <EditUserPassword />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </MainPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

export default connect(mapStateToProps)(Profile);
