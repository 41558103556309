import {
    GET_TESTIMONIALS,
    SET_TESTIMONIALS,
    RESET_TESTIMONIALS
} from '../actions/testimonials.js'

const initialState = []

export default (state = initialState, {
    type,
    payload
}) => {
    switch (type) {
        case SET_TESTIMONIALS:
            return [
                ...payload
            ]
        case RESET_TESTIMONIALS:
            return initialState
        case GET_TESTIMONIALS:
        default:
            return state
    }
}