import {
    SET_REDEMPTION,
    RESET_REDEMPTION
} from "../actions/redemptions"

const initialState = {}

export default (state = initialState, {
    type,
    payload
}) => {
    switch (type) {
        case SET_REDEMPTION:
            return {
                ...payload
            }
        case RESET_REDEMPTION:
            return initialState
        default:
            return state
    }
}