import React from "react";
import { connect } from "react-redux";

const HotelPromotionBannerSection = ({ lang }) => (
    <section className="content--1hotelpromotion">
        <div className="container">
            <div className="row">
                <div className="col-xs-12">
                    <h1
                        dangerouslySetInnerHTML={{
                            __html: lang[lang.locales].contents.hotelPromotion.subheading
                        }}
                    />
                </div>
            </div>
        </div>
    </section>
);

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

export default connect(mapStateToProps)(HotelPromotionBannerSection);
