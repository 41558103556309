export const validatePassword = values => {
    const errors = {};

    if (!values.Password) {
        errors.Password = `Required`;
    }

    if (!values.NewPassword) {
        errors.NewPassword = `Required`;
    }

    if (!values.NewPasswordConfirm) {
        errors.NewPasswordConfirm = `Required`;
    }

    if (
        values.NewPassword &&
        values.NewPasswordConfirm &&
        values.NewPassword !== values.NewPasswordConfirm
    ) {
        errors.NewPassword = `Password does not match`;
        errors.NewPasswordConfirm = `Password does not match`;
    }

    return errors;
};

export const asyncValidatePassword = async values => {
    try {
        const axios = require("../actions/axios")();
        const { data: result } = await axios.post(
            "/validate/resetPassword",
            values
        );
        if (Object.keys(result).length > 0) {
            throw result;
        }
        return undefined;
    } catch (err) {
        throw err;
    }
};

export const warn = values => {
    const warnings = {};

    return warnings;
};
