import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPromotions, resetPromotions } from "../../actions/promotions";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";

class ListHotelPromotionSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 0
        };
    }
    componentDidMount() {
        this.props.fetchPromotions();
    }

    componentWillUnmount() {
        this.props.resetPromotions();
    }
    handleClick = index => {
        this.setState({
            selected: index
        });
    };

    render() {
        return (
            <section className="content--hotelpromotionchoose">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <h3 className="title-hotelpromotion">
                                {
                                    this.props.lang[this.props.lang.locales]
                                        .headings.hotelPromotion
                                }
                            </h3>
                            <div className="chosencity" role="tablist">
                                {!this.props.promotions.length ? (
                                    <Skeleton count={10} duration={10} />
                                ) : (
                                    this.props.promotions.map(
                                        ({ city, hotel }, index) => (
                                            <span key={index}>
                                                <a
                                                    href={`#${city
                                                        .replace(/\s/g, "_")
                                                        .toLowerCase()}`}
                                                    aria-controls={`${city
                                                        .replace(/\s/g, "_")
                                                        .toLowerCase()}`}
                                                    role="tab"
                                                    data-toggle="tab"
                                                    className={`${
                                                        this.state.selected ===
                                                        index
                                                            ? "active"
                                                            : ""
                                                    }`}
                                                    onClick={() =>
                                                        this.handleClick(index)
                                                    }
                                                    style={{
                                                        display: "inline-block"
                                                    }}
                                                >
                                                    {_.startCase(
                                                        _.toLower(city)
                                                    )}
                                                </a>
                                                {index ===
                                                this.props.promotions.length - 1
                                                    ? ""
                                                    : "|"}
                                            </span>
                                        )
                                    )
                                )}
                            </div>
                        </div>
                        <div className="tab-content">
                            {this.props.promotions.map(
                                ({ city, hotel }, index) => (
                                    <div
                                        key={index}
                                        role="tabpanel"
                                        className={`tab-pane ${
                                            index === 0 ? "active" : ""
                                        }`}
                                        id={`${city
                                            .replace(/\s/g, "_")
                                            .toLowerCase()}`}
                                    >
                                        <div className="col-xs-12">
                                            <div className="chosenhotel">
                                                <div className="content-hotel">
                                                    {city === "all" ? (
                                                        this.props.promotions[0].hotel.map(
                                                            (
                                                                { city, hotel },
                                                                index
                                                            ) => (
                                                                <div
                                                                    key={index}
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-xs-12">
                                                                            <h3 className="title-city">
                                                                                <i className="fas fa-map-marker-alt" />
                                                                                {` ${_.startCase(
                                                                                    _.toLower(
                                                                                        city
                                                                                    )
                                                                                )}`}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        {hotel.map(
                                                                            (
                                                                                {
                                                                                    Image,
                                                                                    Rating,
                                                                                    Address,
                                                                                    Description,
                                                                                    Points,
                                                                                    Ref,
                                                                                    hotel
                                                                                },
                                                                                index
                                                                            ) => (
                                                                                <div
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    className="col-xs-4"
                                                                                >
                                                                                    <div
                                                                                        className="promotionhotel"
                                                                                        style={{
                                                                                            backgroundImage: `url(${
                                                                                                hotel
                                                                                                    ? Image
                                                                                                        ? Image
                                                                                                        : hotel.Image
                                                                                                    : Image ||
                                                                                                      "https://via.placeholder.com/300"
                                                                                            })`
                                                                                        }}
                                                                                    >
                                                                                        <div className="promotionhotel-contentname" />
                                                                                        <div className="promotionhotel-contentdetails">
                                                                                            <div className="contentdetailshotel">
                                                                                                <div className="row">
                                                                                                    <div className="col-xs-8">
                                                                                                        <div className="contentnamehotel">
                                                                                                            <h3 className="title-hotel">
                                                                                                                {hotel
                                                                                                                    ? hotel.Name
                                                                                                                    : Ref}
                                                                                                            </h3>
                                                                                                            <img
                                                                                                                src={
                                                                                                                    process
                                                                                                                        .env
                                                                                                                        .PUBLIC_URL +
                                                                                                                    `/assets/images/content/${
                                                                                                                        hotel
                                                                                                                            ? Rating
                                                                                                                                ? Rating
                                                                                                                                : hotel.Rating
                                                                                                                            : Rating
                                                                                                                    }-stars.png`
                                                                                                                }
                                                                                                                alt=""
                                                                                                            />
                                                                                                            <p className="textalamat">
                                                                                                                {_.startCase(
                                                                                                                    _.toLower(
                                                                                                                        hotel
                                                                                                                            ? Address
                                                                                                                                ? Address
                                                                                                                                : hotel.Address
                                                                                                                            : Address
                                                                                                                    )
                                                                                                                )}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-xs-4">
                                                                                                        <div className="extrapointcontent">
                                                                                                            <p className="point">
                                                                                                                {
                                                                                                                    Points
                                                                                                                }
                                                                                                            </p>
                                                                                                            <p className="pointtext">
                                                                                                                Extra
                                                                                                                <br />
                                                                                                                Point
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-xs-12">
                                                                                                        <p className="textcheckoutperiod">
                                                                                                            {
                                                                                                                Description
                                                                                                            }
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )
                                                        )
                                                    ) : (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-xs-12">
                                                                    <h3 className="title-city">
                                                                        <i className="fas fa-map-marker-alt" />
                                                                        {` ${_.startCase(
                                                                            _.toLower(
                                                                                city
                                                                            )
                                                                        )}`}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                {hotel.map(
                                                                    (
                                                                        {
                                                                            Image,
                                                                            Rating,
                                                                            Address,
                                                                            Description,
                                                                            Points,
                                                                            Ref,
                                                                            hotel
                                                                        },
                                                                        index
                                                                    ) => (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                            className="col-xs-4"
                                                                        >
                                                                            <div
                                                                                className="promotionhotel"
                                                                                style={{
                                                                                    backgroundImage: `url(${
                                                                                        hotel
                                                                                            ? Image
                                                                                                ? Image
                                                                                                : hotel.Image
                                                                                            : Image ||
                                                                                              "https://via.placeholder.com/300"
                                                                                    })`
                                                                                }}
                                                                            >
                                                                                <div className="promotionhotel-contentname" />
                                                                                <div className="promotionhotel-contentdetails">
                                                                                    <div className="contentdetailshotel">
                                                                                        <div className="row">
                                                                                            <div className="col-xs-8">
                                                                                                <div className="contentnamehotel">
                                                                                                    <h3 className="title-hotel">
                                                                                                        {hotel
                                                                                                            ? hotel.Name
                                                                                                            : Ref}
                                                                                                    </h3>
                                                                                                    <img
                                                                                                        src={
                                                                                                            process
                                                                                                                .env
                                                                                                                .PUBLIC_URL +
                                                                                                            `/assets/images/content/${
                                                                                                                hotel
                                                                                                                    ? Rating
                                                                                                                        ? Rating
                                                                                                                        : hotel.Rating
                                                                                                                    : Rating
                                                                                                            }-stars.png`
                                                                                                        }
                                                                                                        alt=""
                                                                                                    />
                                                                                                    <p className="textalamat">
                                                                                                        {_.startCase(
                                                                                                            _.toLower(
                                                                                                                hotel
                                                                                                                    ? Address
                                                                                                                        ? Address
                                                                                                                        : hotel.Address
                                                                                                                    : Address
                                                                                                            )
                                                                                                        )}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xs-4">
                                                                                                <div className="extrapointcontent">
                                                                                                    <p className="point">
                                                                                                        {
                                                                                                            Points
                                                                                                        }
                                                                                                    </p>
                                                                                                    <p className="pointtext">
                                                                                                        Extra
                                                                                                        <br />
                                                                                                        Point
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xs-12">
                                                                                                <p className="textcheckoutperiod">
                                                                                                    {
                                                                                                        Description
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        promotions: state.promotions,
        lang: state.lang
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchPromotions: () => dispatch(fetchPromotions()),
        resetPromotions: () => dispatch(resetPromotions())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListHotelPromotionSection);
