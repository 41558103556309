import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchTestimonials, resetTestimonials } from "../../actions/testimonials";

class TestimonialSection extends Component {
  componentDidMount() {
    this.props.fetch();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const {
      testimonials,
      lang,
    } = this.props;
    const {
      locales,
    } = lang;
    const {
      headings
    } = lang[locales];
    return testimonials.length === 0 ? (<div />) : (
      <section className="content-testi">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <h1>{headings.testimonial}</h1>
            </div>
            <div className="col-xs-12 carousel-testi">
              <div
                className="carousel slide"
                id="quote-carousel"
                data-ride="carousel"
              >
                <ol className="carousel-indicators">
                  {
                    testimonials.map(({id}, index) => {
                      return (
                        <li key={id} data-target="#quote-carousel" data-slide-to={index} className={index === 0 ? "active" : ""} />
                      )
                    })
                  }
                </ol>
                <div className="carousel-inner">
                  {testimonials.map(
                    ({ id, Name, Agent, City, Testimony, TestimonyEn, Image }, index) => (
                      <div
                        key={id}
                        className={`item ${index === 0 ? "active" : ""}`}
                      >
                        <blockquote>
                          <div className="row">
                            <div className="col-sm-3 text-center">
                              <i className="fas fa-quote-left" />
                              <img
                                className="img-circle"
                                src={Image || `https://via.placeholder.com/200/CCCCCC/CCCCCC`}
                                style={{ width: 200, height: 200 }}
                                alt={Name}
                              />
                              <div>
                                <p className="name">{Name}</p>
                                <p className="tourism-name">
                                  {Agent}, {City}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-9">
                              <p className="quote-testimoni">
                                {locales !== 'id' ? TestimonyEn : Testimony}
                              </p>
                            </div>
                          </div>
                        </blockquote>
                      </div>
                    )
                  )}
                </div>
                <a
                  data-slide="prev"
                  href="#quote-carousel"
                  className="left carousel-control hidden-xs hidden-sm"
                >
                  <i className="glyphicon glyphicon-menu-left" />
                </a>
                <a
                  data-slide="next"
                  href="#quote-carousel"
                  className="right carousel-control hidden-xs hidden-sm"
                >
                  <i className="glyphicon glyphicon-menu-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

TestimonialSection.propTypes = {
  testimonials: PropTypes.array.isRequired,
  lang: PropTypes.object.isRequired,
};

TestimonialSection.defaultProps = {
  testimonials: [],
  lang: {
    locales: 'en',
    en: {
      headings: {
        testimonial: 'Testimonials'
      }
    }
  }
};

const mapStateToProps = state => {
  return {
      testimonials: state.testimonials,
      lang: state.lang,
  }
}

const mapDispatchToProps = (dispatch, ownProp) => {
  return {
      fetch: () => dispatch(fetchTestimonials()),
      reset: () => dispatch(resetTestimonials())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialSection);
