export const SET_AMAZING = 'SET_AMAZING';
export const RESET_AMAZING = 'RESET_AMAZING';

export const setAmazing = payload => {
    return {
        type: SET_AMAZING,
        payload,
    };
};

export const resetAmazing = () => {
    return {
        type: RESET_AMAZING,
    };
};

export const fetchAmazing = () => async (dispatch, getState) => {
    try {
        const axios = require("./axios")();
        const {status, data} = await axios.get('/amazing', {
            params: {
                Active: true,
            }
        });
        if (status >= 200 && status < 300 && data && data.length > 0) {
            return dispatch(setAmazing(data[0]));
        }
        return;
    } catch (error) {
        throw error;
    }
};