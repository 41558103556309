import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux'

const Footer = ({lang, isLogin, location: {pathname}, ...props}) => {
    return (
        <div>
            <section className="footer-top-login">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-2 logo-footer">
                            <NavLink to="/">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/logo/mg-logo.png`} alt="MG Friends" />
                            </NavLink>
                        </div>
                        <div className="col-xs-3 address-footer">
                            <h4>{ lang[lang.locales].misc.building }</h4>
                            <p>
                                { lang[lang.locales].misc.address }
                            </p>
                            <p> 
                                <span>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/phone.png`} alt="" />
                                </span>
                                { lang[lang.locales].misc.phone }
                            </p>
                            {/* <p>
                                <span>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/fax.png`} alt="" />
                                </span>
                                { lang[lang.locales].misc.fax }
                            </p> */}
                            <p>
                                <span>
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/mail.png`} alt="" />
                                </span>
                                { lang[lang.locales].misc.mail }
                            </p>
                        </div>
                        <div className="col-xs-7 navbar--bottom">
                            {
                                isLogin === false &&
                                <ul className="list-menu-footer">
                                    <li className={pathname && pathname === '/' ? `active` : undefined}>
                                        <NavLink to="/">{ lang[lang.locales].navigations.home }</NavLink>
                                    </li>
                                    <li className={pathname && pathname === '/about' ? `active` : undefined}>
                                        <NavLink to="/about">{ lang[lang.locales].navigations.about }</NavLink>
                                    </li>
                                    <li className={pathname && pathname === '/join' ? `active` : undefined}>
                                        <NavLink to="/join">{ lang[lang.locales].navigations.join }</NavLink>
                                    </li>
                                    <li className={pathname && pathname === '/contact' ? `active` : undefined}>
                                        <NavLink to="/contact">{ lang[lang.locales].navigations.contact }</NavLink>
                                    </li>
                                    <li className="divider-menu"> | </li>
                                    <li>
                                        <NavLink to="/#login-box" search="#login-box">{ lang[lang.locales].navigations.login }</NavLink>
                                    </li>
                                    <li>
                                        <a href="#/" data-toggle="modal" data-target=".signupModal">{ lang[lang.locales].navigations.signup }</a>
                                    </li>
                                </ul>
                            }
                            {
                                isLogin === true &&
                                <ul className="list-menu-footer">
                                    <li className={pathname && (pathname === '/rewards' || pathname === '/') ? `active` : undefined}>
                                        <NavLink to="/rewards">{lang[lang.locales].navigations.rewards}</NavLink>
                                    </li>
                                    <li className={pathname && pathname === '/transactions' ? `active` : undefined}>
                                        <NavLink to="/transactions">{lang[lang.locales].navigations.transactions}</NavLink>
                                    </li>
                                    <li className={pathname && pathname === '/promotions' ? `active` : undefined}>
                                        <NavLink to="/promotions">{lang[lang.locales].navigations.promotions}</NavLink>
                                    </li>
                                    <li className={pathname && pathname === '/redemptions' ? `active` : undefined}>
                                        <NavLink to="/redemptions">{lang[lang.locales].navigations.redemptions}</NavLink>
                                    </li>
                                </ul>
                            }
                            <div className="list-social-media">
                                <div className="title-list-social-media">
                                    <p>
                                        {lang[lang.locales].actions.follow}
                                    </p>
                                </div>
                                <div className="social-media text-center">
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/mgfriends/">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/instagram.png`} alt="" />
                                        {/* <i className="fab fa-instagram"></i> */}
                                    </a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/mgbedbank/">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/facebook.png`} alt="" />
                                        {/* <i className="fab fa-facebook-square"></i> */}
                                    </a>
                                    {/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/MgFriends">
                                        <i className="fab fa-twitter"></i>
                                    </a> */}
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/mg-holiday-pt-mitra-global-holiday">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/linkedin.png`} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="footer-copyright text-center">
                <p>Copyrigth &copy; 2019. Allrights Reserved </p>
            </section>
        </div>
    );
};

Footer.propTypes = {};

Footer.defaultProps = {};

const mapStateToProps = state => {
    return {
        lang: state.lang,
        isLogin: state.account.isLogin,
    };
};

export default withRouter(connect(mapStateToProps)(Footer));