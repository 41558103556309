import React, { Component } from "react";
import HotelPromotionBannerSection from "../small/HotelPromotionBannerSection";
import TermConditionHotelPromotionSection from "../small/TermConditionHotelPromotionSection";
import ListHotelPromotionSection from "../small/ListHotelPromotionSection";

import MainPage from './MainPage';

class HotelPromotion extends Component {
  render() {
    return (
      <MainPage>
        <HotelPromotionBannerSection />
        <ListHotelPromotionSection />
        <TermConditionHotelPromotionSection />
      </MainPage>
    );
  }
}

export default HotelPromotion;