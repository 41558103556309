import React from 'react';
import PropTypes from 'prop-types';

class BlankPage extends React.Component {
    render() {
        return (
            <div>
                { this.props.children }
            </div>
        );
    }
}

BlankPage.propTypes = {
    children: PropTypes.any,
};

BlankPage.defaultProp = {
    children: null,
};

export default BlankPage;