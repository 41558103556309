export const GET_SIGNUP = "GET_SIGNUP";
export const SET_SIGNUP = "SET_SIGNUP";
export const RESET_SIGNUP = "RESET_SIGNUP";

export const getSignup = () => (dispatch, getState) => {
    return getState().signup;
};

export const setSignup = payload => {
    return {
        type: SET_SIGNUP,
        payload
    };
};

export const resetSignup = () => {
    return {
        type: RESET_SIGNUP
    };
};

export const signup = ({ values, history, reset }) => async (
    dispatch,
    getState
) => {
    try {
        const axios = require("./axios")();
        const { status } = await axios.post("/members", values);
        if (status >= 200 && status < 300) {
            const { lang } = getState();
            const { locales } = lang;
            const { notifications } = lang[locales];

            const Swal = require("sweetalert2");
            Swal.fire({
                type: "success",
                title: notifications.signup.success,
                onClose: () => window.location.reload()
            });
        }
        return;
    } catch (error) {
        const SubmissionError = require("redux-form").SubmissionError;
        throw new SubmissionError(error.response);
    }
};
