import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import account from './account.js';
import accountStatement from './accountStatement.js';
import accountStatements from './accountStatements.js';
import amazing from './amazing';
import amazingProgress from './amazingProgress';
import auth from './auth.js';
import lang from './lang'
import page from './page.js';
import pages from './pages.js';
import promotion from './promotion.js';
import promotions from './promotions.js';
import redemption from './redemption.js';
import redemptions from './redemptions.js';
import resetPassword from './resetPassword.js';
import reward from './reward.js';
import rewardCategories from './rewardCategories.js';
import rewards from './rewards.js';
import signup from './signup.js';
import testimonial from './testimonial.js';
import testimonials from './testimonials.js';
import transaction from './transaction.js';
import transactions from './transactions.js';

export default combineReducers({
    account,
    accountStatement,
    accountStatements,
    amazing,
    amazingProgress,
    auth,
    form,
    lang,
    page,
    pages,
    promotion,
    promotions,
    redemption,
    redemptions,
    resetPassword,
    reward,
    rewardCategories,
    rewards,
    signup,
    testimonial,
    testimonials,
    transaction,
    transactions
});