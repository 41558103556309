import React from 'react';
import CarouselSection from '../small/CarouselSection';
import BannerPromotionSection from '../member/BannerPromotionSection';
import RewardSection from '../../containers/rewards/List';
import TestimonialSection from '../small/TestimonialSection';
import TermConditionSection from '../small/TermConditionSection';
import MainPage from './MainPage';

export default () => (
    <MainPage>
        <CarouselSection />
        <BannerPromotionSection />
        <RewardSection />
        <TermConditionSection />
        <TestimonialSection />
    </MainPage>
);
