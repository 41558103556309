import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MainPage from "./MainPage";

const PrivacyPolicy = ({ lang, ...props }) => {
    return (
        <MainPage title={lang[lang.locales].navigations.privacyPolicy}>
            <section className="content--1join">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 text-center">
                            <h1>
                                {lang[lang.locales].contents.privacyPolicy.subheading}
                            </h1>
                        </div>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: lang[lang.locales].contents.privacyPolicy.body
                        }}
                    />
                </div>
            </section>
        </MainPage>
    );
};

PrivacyPolicy.propTypes = {
    lang: PropTypes.object.isRequired
};

PrivacyPolicy.defaultProps = {};

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

export default connect(mapStateToProps)(PrivacyPolicy);
