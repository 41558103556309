import { SET_AMAZING, RESET_AMAZING } from '../actions';

const initialState = {};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_AMAZING:
            return {
                ...state,
                ...payload,
            };
        case RESET_AMAZING:
            return initialState;
        default:
            return state;
    }
}