import _ from "lodash";
import { SET_PROMOTIONS, RESET_PROMOTIONS } from "../actions/promotions.js";

const promotionsInitialState = [];

export default (state = promotionsInitialState, { type, payload }) => {
    switch (type) {
        case SET_PROMOTIONS:
            let newState = [];

            for (const promotion of payload.rows) {
                const { City } = promotion;
                
                if (City === null) {
                    newState.push({
                        city: _.lowerCase("other city"),
                        hotel: [promotion]
                    });
                } else {
                    newState.push({
                        city: _.lowerCase(City),
                        hotel: [promotion]
                    });
                }
            }

            var result = [];

            newState.forEach(item => {
                var existing = result.filter((v, i) => {
                    return v.city === item.city;
                });
                if (existing.length) {
                    var existingIndex = result.indexOf(existing[0]);
                    result[existingIndex].hotel = result[
                        existingIndex
                    ].hotel.concat(item.hotel);
                } else {
                    result.push(item);
                }
            });

            result.forEach((element, i) => {
                if (element.city === "other city") {
                    result.splice(i, 1);
                    result.push(element);
                }
            });

            let all = [];
            result.forEach(({ city, hotel }) => {
                all.push({ city, hotel });
            });

            result.unshift({ city: "all", hotel: all });

            return result;
        case RESET_PROMOTIONS:
            return promotionsInitialState;
        default:
            return state;
    }
};
