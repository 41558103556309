export const GET_PAGES = "GET_PAGES";
export const SET_PAGES = "SET_PAGES";
export const RESET_PAGES = "RESET_PAGES";

export const GET_PAGE = "GET_PAGE";
export const SET_PAGE = "SET_PAGE";
export const RESET_PAGE = "RESET_PAGE";

export const getPages = () => (dispatch, getState) => {
    return getState().pages;
};

export const setPages = payload => {
    return {
        type: SET_PAGES,
        payload
    };
};

export const resetPages = () => {
    return {
        type: RESET_PAGES
    };
};

export const getPage = () => (dispatch, getState) => {
    return getState().page;
};

export const setPage = payload => {
    return {
        type: SET_PAGE,
        payload
    };
};

export const resetPage = () => {
    return {
        type: RESET_PAGE
    };
};

export const fetchPages = () => (dispatch, getState) => {
    const Country = require("../config/config").getCountry();
    const axios = require("./axios")();
    axios
        .get("/pages", {
            params: {
                Country,
                Active: true,
                Publish: true,
                showAll: true
            }
        })
        .then(({ status, headers, data: rows }) => {
            if (status === 200) {
                dispatch(
                    setPages({
                        count: headers["x-total-count"],
                        rows
                    })
                );
            }
        });
};

export const fetchPage = ({ slug, history }) => (dispatch, getState) => {
    if (!slug) {
        return;
    }
    const axios = require("./axios")();
    axios
        .get(`/pages/slug/${slug}`)
        .then(({ status, data }) => {
            if (status === 200) {
                dispatch(setPage(data));
            }
        })
        .catch(() => history.push("/"));
};

export const fetchCustomPage = ({ path, history }) => (dispatch, getState) => {
    if (!path) {
        return;
    }
    const axios = require("./axios")();
    axios
        .get(`/pages/slug${path}`, {
            params: {
                Custom: true
            }
        })
        .then(({ status, data }) => {
            if (status === 200) {
                dispatch(setPage(data));
            }
        })
        .catch(() => history.push("/"));
};
