const getCountry = () => {
    if (['localhost','mgfriends.com'].includes(window.location.hostname)) {
        return 'ID';
    }
    
    const domain = window.location.hostname.split('.')[0];

    switch (domain.toLowerCase()) {
        case 'bn':
            return 'BN';
        case 'my':
            return 'MY';
        case 'ph':
            return 'PH';
        case 'sg':
            return 'SG';
        case 'th':
            return 'TH';
        case 'vn':
            return 'VN';
        default:
            return 'ID';
    }
}

module.exports = {
    getCountry,
}