import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import MainPage from './MainPage';

const PointsExchangeProcedurePage = ({ lang }) => {
    return (
        <MainPage>
            <section className="redemption-sect">
                <div className="container">
                    <div className="redemption-div">
                        <div className="row">
                            <div className="col-xs-12">
                                <h1 className="title-redemption">
                                    {
                                        lang[lang.locales].headings
                                            .redemptionProcedure
                                    }
                                </h1>
                            </div>

                            <div className="col-xs-12">
                                <div className="content-Redemption">
                                    <div className="tabel-Redemption">
                                        <div className="row">
                                            <div className="col-xs-3 text-center">
                                                <div className="side-redemption-picture-left">
                                                    <img
                                                        src={`${
                                                            process.env.PUBLIC_URL
                                                        }assets/images/content/cash-voucher-500rb.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-9">
                                                <div className="side-redemption-text-right">
                                                    <h2>
                                                        {
                                                            lang[lang.locales]
                                                                .contents
                                                                .redemptionProcedure
                                                                .subheading.cash
                                                        }
                                                    </h2>
                                                    <ol
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                lang[lang.locales]
                                                                    .contents
                                                                    .redemptionProcedure
                                                                    .body.cash
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tabel-Redemption">
                                        <div className="row">
                                            <div className="col-xs-3 text-center">
                                                <div className="side-redemption-picture-left">
                                                    <img
                                                        src={`${
                                                            process.env.PUBLIC_URL
                                                        }assets/images/content/hotel-voucher1.jpg`}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-9">
                                                <div className="side-redemption-text-right">
                                                    <h2>
                                                        {
                                                            lang[lang.locales]
                                                                .contents
                                                                .redemptionProcedure
                                                                .subheading.voucher
                                                        }
                                                    </h2>
                                                    <ol
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                lang[lang.locales]
                                                                    .contents
                                                                    .redemptionProcedure
                                                                    .body.voucher
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tabel-Redemption">
                                        <div className="row">
                                            <div className="col-xs-3 text-center">
                                                <div className="side-redemption-picture-left">
                                                    <img
                                                        src={`${
                                                            process.env.PUBLIC_URL
                                                        }assets/images/content/product-cayla.png`}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-9">
                                                <div className="side-redemption-text-right">
                                                    <h2>
                                                        {
                                                            lang[lang.locales]
                                                                .contents
                                                                .redemptionProcedure
                                                                .subheading.product
                                                        }
                                                    </h2>
                                                    <ol
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                lang[lang.locales]
                                                                    .contents
                                                                    .redemptionProcedure
                                                                    .body.product
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12">
                                <div className="backToReward">
                                    <Link to="/rewards">
                                        <i className="fas fa-angle-left" />
                                        {
                                            lang[lang.locales].contents
                                                .redemptionProcedure.body.back
                                        }
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </MainPage>
    );
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

export default connect(mapStateToProps)(PointsExchangeProcedurePage);
