import { reset } from "redux-form";
import Swal from "sweetalert2";

export const GET_RESETPASSWORD = "GET_RESETPASSWORD";
export const SET_RESETPASSWORD = "SET_RESETPASSWORD";
export const RESET_RESETPASSWORD = "RESET_RESETPASSWORD";

export const getResetPassword = () => (dispatch, getState) => {
    return getState().account;
};

export const setResetPassword = payload => {
    return {
        type: SET_RESETPASSWORD,
        payload
    };
};

export const resetResetPassword = () => {
    return {
        type: RESET_RESETPASSWORD
    };
};

export const fetchResetPassword = ({ token, history }) => (
    dispatch,
    getState
) => {
    const axios = require("./axios")();
    axios.get(`/reset-passwords/${token}`).then(({ data }) => {
        if (data === null) {
            history.push("/");
        }
        dispatch(setResetPassword(data));
    });
};

export const resetPassword = ({ values, history, match }) => (
    dispatch,
    getState
) => {
    const axios = require("./axios")();

    axios
        .put(`/reset-passwords/${match.params.token}`, values)
        .then(({ data, status }) => {
            if (status >= 200 && status < 400) {
                const { lang } = getState();
                const { locales } = lang;
                const { notifications } = lang[locales];

                dispatch(reset("resetPassword"));
                history.push("/");
                Swal.fire({
                    type: "success",
                    title: notifications.changePassword.success,
                    onClose: () => window.location.reload()
                });
            }
        })
        .catch(error => console.log(error));
};
