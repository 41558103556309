/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { connect } from "react-redux"
import { fetchReward } from "../../actions/rewards"

const Card = ({
    reward: {
        id,
        Image,
        Value,
        Name,
        NameEn,
        Points
    },
    onClick,
    lang
}) => (
    <div className="col-xs-4 text-center">
        <div className="pict-vou">
            <img src={Image} alt={ Value ? new Intl.NumberFormat('en-US').format(Value) : Name } />
        </div>
        <div className="cash">
            {/* {Value ? new Intl.NumberFormat('en-US').format(Value) : Name} */}
            {lang.locales === "id" ? Name : NameEn}
        </div>
        <button type="button" className="button-a-point" data-toggle="modal" data-target=".rewardModalCashVoucher" onClick={ () => onClick(id) }>
            <div className="point">
                {`${new Intl.NumberFormat('en-US').format(Points)} ${lang[lang.locales].misc.point}`}
                <i className="bottom left"></i>
                <i className="bottom right"></i>
            </div>
        </button>
        {/* <a href="#" className="a-point" data-toggle="modal" data-target=".rewardModalCashVoucher" >
            <div className="point">
                {`${new Intl.NumberFormat('en-US').format(Points)} POINTS`}
                <i className="bottom left" />
                <i className="bottom right" />
            </div>
        </a> */}
    </div>
)

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClick: (id) => dispatch(fetchReward(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Card)