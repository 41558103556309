import React from "react";
import { connect } from "react-redux";

const TermConditionHotelPromotionSection = ({ lang }) => (
    <section className="terms-conditions-hotelpromotion">
        <div className="container">
            <div className="row">
                <div className="col-xs-12">
                    <div
                        className="title-tac"
                        dangerouslySetInnerHTML={{
                            __html:
                                lang[lang.locales].contents.hotelPromotion
                                    .termCondition
                        }}
                    />
                </div>
            </div>
        </div>
    </section>
);
const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

export default connect(mapStateToProps)(TermConditionHotelPromotionSection);
