import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchPage, resetPage } from '../../actions/pages'

import MainPage from './MainPage';

class LandingPage extends Component {
    componentWillMount() {
        const {
            match: {
                params: {
                    slug
                }
            },
            history
        } = this.props
        this.props.fetchPage({
            slug,
            history
        })
    }

    componentWillUnmount() {
        this.props.resetPage()
    }

    render() {
        return (
            <MainPage>
                <section className="banner-landingpage">
                    <div className="backgroundImage" style={{backgroundImage: `url(${this.props.page.Image})`}} />
                </section>
                <section className="terms-conditions-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                {/* <h1>Terms &amp; Conditions</h1> */}
                            </div>
                            <div className="col-xs-12" dangerouslySetInnerHTML={{
                                __html: this.props.page.Description
                            }} />
                        </div>
                    </div>
                </section>
                <section className="social-media-sect">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <h2>Pengen tau petunjuk dan info lainnya? Follow social media MG Friends ya</h2>
                            </div>
                            <div className="col-xs-12">
                                <div className="list-socmed">
                                    <div className="list-socmed-cell">
                                        <div>
                                            <a href="https://www.facebook.com/mgbedbank/">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/facebook-blue.png`} alt="" />
                                                &nbsp;MG Bedbank
                                            </a>
                                        </div>
                                    </div>
                                    <div className="list-socmed-cell">
                                        <div>
                                            <a href="https://www.instagram.com/mgfriends/">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/instagram-blue.png`} alt="" />
                                                &nbsp;MG Friends
                                            </a>
                                        </div>
                                    </div>
                                    <div className="list-socmed-cell">
                                        <div>
                                            <a href="https://www.linkedin.com/company/mg-holiday-pt-mitra-global-holiday">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/linkedin-blue.png`} alt="" />
                                                &nbsp;MG Group
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12">
                                <h3> Masih mikir ? <br /> Pokoknya sering-sering log in dan cari hotelnya di <a href="http://www.mgbedbank.com" target="_blank" rel="noopener noreferrer">MGBedbank.com</a> </h3>
                            </div>
                        </div>
                    </div>
                </section>
            </MainPage>
        )
    }
}

const mapStateToProps = state => {
    return {
        page: state.page,
    }
}

const mapDispatchToProps = (dispatch, ownProp) => {
    return {
        fetchPage: payload => dispatch(fetchPage(payload)),
        resetPage: () => dispatch(resetPage())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage)