import React from 'react';
import { connect } from 'react-redux';
import AmazingRace5Page from './AmazingRace5Page';
import AmazingRace5ProgressPage from './AmazingRace5ProgressPage';

const AmazingRacePage = ({isLogin, ...props}) => isLogin ? (<AmazingRace5ProgressPage />) : (<AmazingRace5Page />);

const mapStateToProps = state => {
    return {
        isLogin: state.account.isLogin,
    };
};

export default connect(mapStateToProps)(AmazingRacePage);