import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MainPage from "./MainPage";

const JoinPage = ({ lang, ...props }) => {
    return (
        <MainPage title={lang[lang.locales].navigations.join}>
            <section className="content--1join" style={{ backgroundColor: '#004268'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 text-center">
                            <h1 style={{ color: '#fff' }}>
                                {lang[lang.locales].contents.join.subheading}
                            </h1>
                            <img
                                src={`${
                                    process.env.PUBLIC_URL
                                }/assets/images/content/join-detail.png`}
                                alt=""
                            />
                        </div>
                    </div>
                    {/* <div
                        dangerouslySetInnerHTML={{
                            __html: lang[lang.locales].contents.join.body
                        }}
                    /> */}
                </div>
            </section>
        </MainPage>
    );
};

JoinPage.propTypes = {
    lang: PropTypes.object.isRequired
};

JoinPage.defaultProps = {};

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

export default connect(mapStateToProps)(JoinPage);
