import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NotFoundPage } from './components/pages';
import routes from './config/routes';

class App extends Component {
  render() {
    return (
      <Switch>
        {routes.map((props, i) => (<Route key={ i } {...props} />))}
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLogin: state.account.isLogin,
  };
};

export default withRouter(connect(mapStateToProps)(App));
