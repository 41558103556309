import React, { Component } from "react";
import { connect } from "react-redux";
import {
    fetchAccountStatements as fetch,
    resetAccountStatements as reset,
    setAccountStatementsPagination as setPagination
} from "../../actions/accountStatements";

class TableDetailPoint extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleChange(e) {
        e.preventDefault();
        const { value } = e.target;
        const payload = {
            page: 1,
            perPage: parseInt(value)
        };
        this.props.setPagination(payload);
        this.props.fetch();
    }

    handleClick = page => e => {
        e.preventDefault();
        const payload = {
            page: parseInt(page)
        };
        this.props.setPagination(payload);
        this.props.fetch();
    };

    componentWillMount() {
        this.props.fetch();
    }

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const {
            count,
            rows,
            pagination: { page, perPage, start, end }
        } = this.props.accountStatements;
        const moment = require("moment");
        return (
            <div className="" id="riwayat-transaksi">
                <div className="header">
                    <div className="row">
                        <div className="col-xs-6">
                            {/* <input type="text" placeholder="Search" className="form-search" onChange={ e => console.log(e) }/> */}
                        </div>
                        <div className="col-xs-6 text-right">
                            <label htmlFor="">
                                {
                                    this.props.lang[this.props.lang.locales]
                                        .misc.table.page
                                }
                                <select
                                    defaultValue={perPage}
                                    onChange={this.handleChange}
                                >
                                    <option value="25"> 25 </option>
                                    <option value="50"> 50 </option>
                                    <option value="100"> 100 </option>
                                    <option value="200"> 200 </option>
                                </select>
                            </label>
                        </div>
                    </div>
                </div>
                <table width="100%" className="table">
                    <thead>
                        <tr>
                            <th>
                                {
                                    this.props.lang[this.props.lang.locales]
                                        .contents.claim.pointInfo.table.date
                                }
                            </th>
                            <th>
                                {
                                    this.props.lang[this.props.lang.locales]
                                        .contents.claim.pointInfo.table
                                        .description
                                }
                            </th>
                            <th>
                                {
                                    this.props.lang[this.props.lang.locales]
                                        .contents.claim.pointInfo.table.account
                                }
                            </th>
                            <th>
                                {
                                    this.props.lang[this.props.lang.locales]
                                        .contents.claim.pointInfo.table.amount
                                }
                            </th>
                            <th>
                                {
                                    this.props.lang[this.props.lang.locales]
                                        .contents.claim.pointInfo.table.balance
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map(
                            (
                                { Date, Description, Account, Amount, Balance },
                                i
                            ) => (
                                <tr key={i}>
                                    <td>
                                        {moment(Date).format(
                                            "DD MMM YYYY hh:mm:ss"
                                        )}
                                    </td>
                                    <td>{Description}</td>
                                    <td>{Account}</td>
                                    <td>
                                        {new Intl.NumberFormat("en-US").format(
                                            Amount
                                        )}
                                    </td>
                                    <td>
                                        {new Intl.NumberFormat("en-US").format(
                                            Balance
                                        )}
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
                {count > 0 && (
                    <div className="footer">
                        <div className="row">
                            <div className="col-xs-6">
                                <div
                                    className="showing"
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.lang[
                                            this.props.lang.locales
                                        ].misc.table.show
                                            .replace("{start}", start)
                                            .replace("{end}", end)
                                            .replace("{count}", count)
                                    }}
                                />
                            </div>
                            <div className="col-xs-6">
                                <nav aria-label="Page navigation">
                                    <ul className="pagination">
                                        <li>
                                            <a
                                                href="#/"
                                                aria-label="Previous"
                                                onClick={this.handleClick(
                                                    page === 1 ? page : page - 1
                                                )}
                                            >
                                                <i className="glyphicon glyphicon-menu-left" />
                                                {
                                                    this.props.lang[
                                                        this.props.lang.locales
                                                    ].misc.table.previous
                                                }
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#/"
                                                aria-label="Next"
                                                onClick={this.handleClick(
                                                    [0, page].includes(
                                                        Math.ceil(
                                                            count / perPage
                                                        )
                                                    )
                                                        ? page
                                                        : page + 1
                                                )}
                                            >
                                                {
                                                    this.props.lang[
                                                        this.props.lang.locales
                                                    ].misc.table.next
                                                }
                                                <i className="glyphicon glyphicon-menu-right" />
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountStatements: state.accountStatements,
        lang: state.lang
    };
};

const mapDispatchToProps = (dispatch, ownProp) => {
    return {
        fetch: () => dispatch(fetch()),
        reset: () => dispatch(reset()),
        setPagination: payload => dispatch(setPagination(payload))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TableDetailPoint);
