import React from "react";

import MainPage from "./MainPage";
import CarouselSection from "../small/CarouselSection";
import AboutSection from "../guest/AboutSection";
import JoinSection from "../guest/JoinSection";
import TestimonialSection from "../small/TestimonialSection";

class GuestHomePage extends React.Component {
    render() {
        return (
            <MainPage>
                <CarouselSection />
                <JoinSection />
                <AboutSection />
                <TestimonialSection />
            </MainPage>
        );
    }
}

GuestHomePage.propTypes = {};

GuestHomePage.defaultProps = {};

export default GuestHomePage;
