import {
    SET_ACCOUNT,
    GET_ACCOUNT,
    RESET_ACCOUNT
} from '../actions/account.js'

const accountInitialState = {
    isLogin: false,
}

export default (state = accountInitialState, {
    type,
    payload
}) => {
    switch (type) {
        case SET_ACCOUNT:
            return {
                ...state,
                ...payload,
            }
        case RESET_ACCOUNT:
            return accountInitialState
        case GET_ACCOUNT:
        default:
            return state
    }
}