import React from "react";
import { connect } from 'react-redux';

const JoinSection = ({lang}) => (
  <section className="content--2">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 text-center">
          {/* <h1> { lang[lang.locales].headings.join } </h1> */}
          <img
            src={lang.locales === 'id' ? `${
              process.env.PUBLIC_URL
            }/assets/images/content/join-home.png` : `${
              process.env.PUBLIC_URL
            }/assets/images/content/join-home.png` }
            alt="How to Join"
          />
        </div>
      </div>
    </div>
  </section>
);

const mapStateToProps = state => {
  return {
    lang: state.lang,
  };
}

export default connect(mapStateToProps)(JoinSection);
