import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signup } from "../../actions";
import { asyncValidateSignup, validateSignup } from "../../validate";
import { TextInput, EmailInput, PasswordInput } from '../inputs';

const SignupForm = ({ lang, handleSubmit, pristine, submitting, error, valid, ...props}) => {
    const {
        locales
    } = lang;
    const {
        forms,
        actions,
    } = lang[locales];
    return (
        <form onSubmit={ handleSubmit }>
            <TextInput name="AgentCode" label={forms.signup.agentCode} required showError />
            <TextInput name="UserId" label={forms.signup.userId} required showError />
            <TextInput name="Phone" label={forms.signup.phone} showError />
            <EmailInput name="Email" label={forms.signup.email} required showError />
            <PasswordInput name="Password" label={forms.signup.password} required showError />
            <PasswordInput name="PasswordConfirm" label={forms.signup.passwordConfirm} required showError />
            {
                error &&
                <p className="text-center text-danger">{error}</p>
            }
            <div className="text-center">
                <button type="submit" className="btn button-signup" disabled={pristine || submitting || error}>
                    {actions.signup}
                </button>
            </div>
        </form>
    );
};

SignupForm.propTypes = {};

SignupForm.defaultProps = {};

const mapStateToProps = state => {
    return {
        lang: state.lang,
    };
};

export default withRouter(connect(mapStateToProps)(reduxForm({
    form: "signup",
    validate: validateSignup,
    asyncValidate: asyncValidateSignup,
    asyncChangeFields: ['AgentCode', 'UserId', 'Email'],
    onSubmit: (values, dispatch, {history, reset, ...props}) => dispatch(signup({ values, history, reset})),
    // onSubmitFail: (errors, dispatch, submitError, {history, reset, ...props}) => console.error(errors, submitError),
    // onSubmitSuccess: (result, dispatch, {history, reset, ...props}) => reset(),
    touchOnChange: true,
})(SignupForm)));