export const validateForgetPassword = ({
    Email
}) => {
    const errors = {}
    if (!Email) {
        errors.Email = `Required`
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Email)) {
        errors.Email = `Invalid email address`
    }

    return errors;
}

export const warnForgetPassword = values => {
    const warnings = {}

    return warnings
}

export const asyncValidateForgetPassword = async values => {
    try {
        const axios = require("../actions/axios")();
        const {data: result} = await axios.post('/validate/forgetPassword', values);
        if (Object.keys(result).length > 0) {            
            throw result;
        }
        return undefined;
    } catch (err) {
        throw err;
    }
}

export const submitValidateForgetPassword = async ({
    Email,
    Password,
    ...rest
}) => {
    const errors = {}
    if (!Email) {
        errors.Email = `Required`;
        errors._error = `Required`;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Email)) {
        errors.Email = `Invalid email address`;
        errors._error = `Invalid email address`;
    } else if (!Password) {
        errors.Password = `Required`;
        errors._error = `Required`;
    }

    return errors;
}