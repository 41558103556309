import {
    SET_REWARD,
    RESET_REWARD
} from "../actions/rewards"

const initialState = {}

export default (state = initialState, {
    type,
    payload
}) => {
    switch (type) {
        case SET_REWARD:
            return {
                ...payload
            }
        case RESET_REWARD:
            return initialState
        default:
            return state
    }
}