import React, { Component } from "react";
import { connect } from "react-redux";

class StatusBox extends Component {
    render() {
        return (
            <div className="point-status-box">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box">
                                <div className="row text-center">
                                    <ul className="divider-point">
                                        <li className="border">
                                            <div className="title-tag">
                                                {
                                                    this.props.lang[
                                                        this.props.lang.locales
                                                    ].contents.reward.statusBox
                                                        .point
                                                }
                                            </div>
                                            <div className="point-tag">
                                                {`${new Intl.NumberFormat(
                                                    "en-Us"
                                                ).format(
                                                    this.props.user.Point
                                                )} ${
                                                    this.props.lang[
                                                        this.props.lang.locales
                                                    ].misc.point
                                                }`}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="title-tag">
                                                {
                                                    this.props.lang[
                                                        this.props.lang.locales
                                                    ].contents.reward.statusBox
                                                        .status
                                                }
                                            </div>
                                            <div className="box-status-tag">
                                                <div className="backbox-status-tag">
                                                    <p>
                                                        {this.props.user.Genius}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    
                                    {/* <h4 style={{color: '#004268', marginBottom: '5px'}}>
                                    “Dear MG Friends,
                                        All MGF redemptions are temporarily delayed while we focus all resources on supporting customers with re-accommodations or cancelations in this time of crisis.
                                        All points continue to accrue as per normal and claims will resume when current crisis is resolved.” 
                                    </h4> */}
                                </div>
                            </div>
                            <br/>

                            {/**
                             * coba mau buat box kedua
                            */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {
        account: { Point, GeniusMember }
    } = state;
    const user = {
        Point,
        Genius: Boolean(GeniusMember) ? "GENIUS MEMBER" : "SILVER MEMBER"
    };
    const lang = state.lang;
    return {
        user,
        lang
    };
};

export default connect(mapStateToProps)(StatusBox);
