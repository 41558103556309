import {
    SET_RESETPASSWORD,
    RESET_RESETPASSWORD
} from "../actions/resetPassword"

const initialState = {}

export default (state = initialState, {
    type,
    payload
}) => {
    switch (type) {
        case SET_RESETPASSWORD:
            return {
                ...payload
            }
        case RESET_RESETPASSWORD:
            return initialState
        default:
            return state
    }
}