import {
    GET_PAGES,
    SET_PAGES,
    RESET_PAGES
} from "../actions/pages.js"

const initialState = {
    count: 0,
    rows: [],
    first: null,
    next: null,
    prev: null,
    last: null,
}

export default (state = initialState, {
    type,
    payload
}) => {
    switch (type) {
        case SET_PAGES:
            return {
                ...state,
                ...payload
            }
        case RESET_PAGES:
            return initialState
        case GET_PAGES:
        default:
            return state
    }
}