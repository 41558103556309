import React from 'react';
import PropTypes from 'prop-types';

const ModalHeader = ({title, type, ...props}) => {
    return (
        <div className="modal-header text-center">
            {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> */}
            <div
                className="for-close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <img
                    src={`${
                    process.env.PUBLIC_URL
                    }assets/images/icon/close-tab.png`}
                    alt=""
                />
            </div>
            {
                type === 'logo' &&
                <img
                    src={`${
                        process.env.PUBLIC_URL
                    }assets/images/logo/mg-logo.png`}
                    alt=""
                />
            }
            {
                type === 'title' &&
                <h4 className="modal-title">{ title }</h4>
            }
        </div>
    );
};

ModalHeader.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
};

ModalHeader.defaultProps = {
    type: 'title',
};

export default ModalHeader;