export const GET_REDEMPTIONS = "GET_REDEMPTIONS"
export const SET_REDEMPTIONS = "SET_REDEMPTIONS"
export const SET_REDEMPTIONS_PAGINATION = "SET_REDEMPTIONS_PAGINATION"
export const RESET_REDEMPTIONS = "RESET_REDEMPTIONS"

export const GET_REDEMPTION = "GET_REDEMPTION"
export const SET_REDEMPTION = "SET_REDEMPTION"
export const RESET_REDEMPTION = "RESET_REDEMPTION"

export const getRedemptions = () => (dispatch, getState) => {
    return getState().redemptions
}

export const setRedemptions = (payload) => {
    return {
        type: SET_REDEMPTIONS,
        payload
    }
}

export const resetRedemptions = () => {
    return {
        type: RESET_REDEMPTIONS
    }
}

export const getRedemption = () => (dispatch, getState) => {
    return getState().redemption
}

export const setRedemption = (payload) => {
    return {
        type: SET_REDEMPTION,
        payload
    }
}

export const setRedemptionsPagination = payload => {
    return {
        type: SET_REDEMPTIONS_PAGINATION,
        payload
    }
}


export const resetRedemption = () => {
    return {
        type: RESET_REDEMPTION
    }
}

export const fetchRedemptions = () => (dispatch, getState) => {
    const axios = require("./axios")()
    const {
        page,
        perPage
    } = getState().redemptions.pagination
    axios.get("/redemptions", {
            params: {
                page,
                perPage
            }
        })
        .then(({
            status,
            headers,
            data: rows
        }) => {
            if (status === 200) {
                dispatch(setRedemptions({
                    count: headers["x-total-count"],
                    rows
                }))
            }
        })
}

export const fetchRedemption = (id) => (dispatch, getState) => {
    if (!id) {
        return
    }
    const axios = require("./axios")()
    axios.get(`/redemptions/${id}`)
        .then(({
            status,
            data
        }) => {
            if (status === 200) {
                dispatch(setRedemption(data))
            }
        })
}

export const redeem = ({
    RewardID,
    history
}) => async (dispatch, getState) => {
    try {
        const axios = require("./axios")()
        const {
            status,
        } = await axios.post("/redemptions", {
            RewardID
        })
        if (status >= 200 && status < 300) {
            return true
            // return history.push(`/redemptions`)
        }
        return false
    } catch (error) {
        return false
    }
}