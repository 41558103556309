export const validateContact = ({
    Name,
    Email,
    Phone,
    Company,
    Message,
    recaptcha,
    ...rest
}) => {
    const errors = {};

    if (!Name) {
        errors.Name = `Required`;
    }

    if (!Email) {
        errors.Email = `Required`;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Email)) {
        errors.Email = `Invalid email address`;
    }

    if (!Phone) {
        errors.Phone = `Required`;
    }

    if (!Company) {
        errors.Company = `Required`;
    }

    if (!Message) {
        errors.Message = `Required`;
    }

    if (!recaptcha) {
        errors.recaptcha = `Recaptcha Required`;
    }

    return errors;
};

export const warnContact = values => {
    const warnings = {};

    return warnings;
};

export const asyncValidateContact = async values => {
    try {
        const axios = require("../actions/axios")();
        const { data: result } = await axios.post("/validate/contact", values);
        if (Object.keys(result).length > 0) {
            throw result;
        }
        return undefined;
    } catch (err) {
        throw err;
    }
};

export const submitValidateContact = async ({
    Name,
    Email,
    Phone,
    Company,
    Message,
    recaptcha,
    ...rest
}) => {
    try {
        const errors = {};

        if (!Name) {
            errors.Name = "Required";
            errors._error = "Name: required";
        } else if (!Email) {
            errors.Email = "Required";
            errors._error = "Email: required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Email)) {
            errors.Email = "Invalid email address";
            errors._error = "Email: invalid email address";
        } else if (Phone && isNaN(Number(Phone))) {
            errors.Phone = "Numbers only";
            errors._error = "Phone: numbers only";
        } else if (!Company) {
            errors.Company = "Required";
            errors._error = "Company: required";
        } else if (!Message) {
            errors.Message = "Required";
            errors._error = "Message: required";
        } else if (!recaptcha) {
            errors.recaptcha = "Recaptcha Required";
            errors._error = "Recaptcha: required";
        }

        return errors;
    } catch (err) {
        throw err;
    }
};
