import React from "react";
import { connect } from "react-redux";
import TableRedemption from "../small/TableRedemption";
import TableDetailPoint from "../small/TableDetailPoint";

import MainPage from './MainPage';

const RedemptionPage = ({ lang }) => (
    <MainPage>
        <section className="content--1klaimreward">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="form-klaimreward">
                            <div className="form">
                                <h1>{lang[lang.locales].headings.claim}</h1>
                                <div className="form-nav-tab">
                                    <ul className="nav nav-pills" role="tablist">
                                        <li role="presentation" className="active">
                                            <a
                                                href="#info-tukar-hadiah"
                                                aria-controls="info-tukar-hadiah"
                                                role="tab"
                                                data-toggle="tab"
                                            >
                                                {
                                                    lang[lang.locales].contents
                                                        .claim.pointInfo.tab
                                                }
                                            </a>
                                        </li>
                                        <li role="presentation">
                                            <a
                                                href="#info-detail-point"
                                                aria-controls="info-detail-point"
                                                role="tab"
                                                data-toggle="tab"
                                            >
                                                {
                                                    lang[lang.locales].contents
                                                        .claim.redeemInfo.tab
                                                }
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div
                                            role="tabpanel"
                                            className="tab-pane active"
                                            id="info-tukar-hadiah"
                                        >
                                            <TableRedemption />
                                        </div>
                                        <div
                                            role="tabpanel"
                                            className="tab-pane"
                                            id="info-detail-point"
                                        >
                                            <TableDetailPoint />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </MainPage>
);

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

export default connect(mapStateToProps)(RedemptionPage);
