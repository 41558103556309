import {
    SET_CREDENTIALS,
    GET_CREDENTIALS,
    RESET_CREDENTIALS,
} from '../actions/auth'

const authInitialState = {
    Email: null,
    Password: null,
}

export default (state = authInitialState, {
    type,
    payload
}) => {
    switch (type) {
        case SET_CREDENTIALS:
            const newState = {
                ...state,
                ...payload
            }
            return newState
        case RESET_CREDENTIALS:
            return authInitialState
        case GET_CREDENTIALS:
        default:
            return state
    }
}