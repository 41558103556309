import React from 'react';
import { connect } from 'react-redux';

import GuestHeader from './GuestHeader';
import MemberHeader from './MemberHeader';
import GuestModals from '../guest/Modals'

const Header = ({ isLogin }) => isLogin ? (
    <MemberHeader />
) : (
    <div>
        <GuestHeader />
        <GuestModals />
    </div>
);

const mapStateToProps = state => {
    return {
        isLogin: state.account.isLogin
    }
}

export default connect(mapStateToProps)(Header);