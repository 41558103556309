import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { redeem, fetchAccount } from '../../actions';

class ClaimButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: this.props.disabled || false,
        }
    }

    componentDidMount() {
        const {
            account,
            reward
        } = this.props;
        if (account.Point < (account.GeniusMember ? reward.Points - reward.PointsDiscount : reward.Points)) {
            this.setState({
                ...this.state,
                disabled: true,
            });
        }
    }

    render() {
        return (
            <button
                type="button"
                className="btn button-klaim"
                data-dismiss="modal"
                disabled={this.state.disabled}
                onClick={async () => {
                    try {
                        await this.setState({
                            ...this.state,
                            disabled: true,
                        });
                        const result = await this.props.redeem({
                            RewardID: this.props.reward.id,
                            history: this.props.history,
                        });
                        await this.props.fetchAccount();
                        await this.setState({
                            ...this.state,
                            disabled: false,
                        });
                        return result ? this.props.history.push("/redemptions") : null;
                    } catch (error) {
                        console.error({ error });
                    }
                }}
            >
                {
                    this.props.lang[this.props.lang.locales].contents.reward.modal
                        .action
                }
            </button>
        );
    }
}

ClaimButton.propTypes = {
    disabled: PropTypes.bool,
};

ClaimButton.defaultProps = {
    disabled: false,
};

const mapStateToProps = state => {
    return {
        account: state.account,
        lang: state.lang,
        reward: state.reward,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        redeem: payload => dispatch(redeem(payload)),
        fetchAccount: () => dispatch(fetchAccount()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClaimButton));