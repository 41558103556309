import React from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { submitAgent } from "../actions/account";
import { asyncValidateChangeAgent, validateChangeAgent } from "../validate";

const renderField = props => {
    const {
        input,
        label,
        type,
        meta: { touched, error }
    } = props;
    const classes = ["form-group"];
    return (
        <div
            className={
                touched && error
                    ? classes.concat(["has-error", "has-feedback"]).join(" ")
                    : classes.join(" ")
            }
            style={{ textAlign: "center" }}
        >
            <label className="control-label sr-only">{label}</label>
            <input
                className="form-control"
                {...input}
                placeholder={label}
                type={type}
                aria-describedby={`${label}Status`}
            />
            {touched && error && (
                <span
                    className="glyphicon glyphicon-remove form-control-feedback"
                    aria-hidden="true"
                />
            )}
            {touched && error && <small id={`${label}Status`}>{error}</small>}
        </div>
    );
};

const EditUserAgent = ({ handleSubmit, account, lang }) => (
    <form onSubmit={handleSubmit}>
        <div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeAgent.currentAgent}
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <p>{account.AgentCode}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeAgent.currentUser}
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <p>{account.UserId}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeAgent.newAgent}*
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="NewAgentCode"
                                label={`${
                                    lang[lang.locales].forms.changeAgent
                                        .newAgent
                                }`}
                            />
                            <p className="small-info">
                                *
                                {lang[lang.locales].forms.changeAgent.noteAgent}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="formgroupep">
                <div className="row">
                    <div className="col-xs-4">
                        <div className="tag-input-name">
                            {lang[lang.locales].forms.changeAgent.newUser}*
                        </div>
                    </div>
                    <div className="col-xs-8">
                        <div className="input-name">
                            <Field
                                component={renderField}
                                type="text"
                                name="NewUserID"
                                label={`${
                                    lang[lang.locales].forms.changeAgent.newUser
                                }`}
                            />
                            <p className="small-info">
                                *{lang[lang.locales].forms.changeAgent.noteUser}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="formbutton">
                <div className="row">
                    <div className="col-xs-12">
                        <button>
                            {lang[lang.locales].forms.changeAgent.save}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
);

const mapStateToProps = state => {
    return {
        account: state.account,
        lang: state.lang
    };
};

const mapDispatchToProps = (dispatch, { history }) => {
    return {
        onSubmit: values =>
            dispatch(
                submitAgent({
                    values,
                    history
                })
            )
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "changeAgent",
            validate: validateChangeAgent,
            asyncValidate: asyncValidateChangeAgent,
            asyncChangeFields: ["NewAgentCode", "NewUserID"]
        })(EditUserAgent)
    )
);
