import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import MainPage from './MainPage';

const AboutPage = ({ lang, ...props }) => {
    return (
        <MainPage title={lang[lang.locales].navigations.about}>
            <section className="content--1about">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 text-center">
                            <h1 dangerouslySetInnerHTML={{ __html: lang[lang.locales].contents.about.subheading }}/>
                            <p className="mt700px" dangerouslySetInnerHTML={{ __html: lang[lang.locales].contents.about.body }}/>
                        </div>
                    </div>
                </div>
            </section>
        </MainPage>
    );
};

AboutPage.propTypes = {
    lang: PropTypes.object.isRequired,
};

AboutPage.defaultProps = {};

const mapStateToProps = state => {
    return {
        lang: state.lang,
    };
}

export default connect(mapStateToProps)(AboutPage);