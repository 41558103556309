import { reset } from "redux-form";
import Swal from "sweetalert2";

import { fetchAccount, resetAccount, setAccount } from "./account";

export const GET_CREDENTIALS = "GET_CREDENTIALS";
export const SET_CREDENTIALS = "SET_CREDENTIALS";
export const RESET_CREDENTIALS = "RESET_CREDENTIALS";

export const getCredentials = () => (dispatch, getState) => {
    return getState().auth;
};

export const setCredentials = payload => {
    return {
        type: SET_CREDENTIALS,
        payload
    };
};

export const resetCredentials = () => {
    return {
        type: RESET_CREDENTIALS
    };
};

export const login = ({ credentials, history, reset }) => async (
    dispatch,
    getState
) => {
    try {
        const axios = require("./axios")();
        const {
            status,
            data: { token }
        } = await axios.post("/members/login", credentials);
        if (status >= 200 && status < 300) {
            localStorage.setItem("token", token);
            await dispatch(
                setAccount({
                    isLogin: true
                })
            );
            await dispatch(fetchAccount());
            await dispatch(resetCredentials());
            // Swal.fire({
            //     type: "warning",
            //     title: `Dear MG Friends`,
            //     text:`All MGF redemptions are temporarily delayed while we focus all resources on supporting customers with re-accommodations or cancelations in this time of crisis.
            //     All points continue to accrue as per normal and claims will resume when current crisis is resolved.`,
            //     onClose: () => window.location.reload()
            // });
            // dispatch(resetSignup());
            await history.push("/rewards");
        }
    } catch (error) {
        const { data } = error.response;
        const Swal = require("sweetalert2");
        return Swal.fire({
            type: "error",
            title: data.error,
            onClose: () => {
                reset();
                dispatch(resetCredentials());
            }
        });
    }
};

export const isLogin = () => async (dispatch, getState) => {
    const token = localStorage.getItem("token");
    if (token) {
        dispatch(
            setAccount({
                isLogin: true
            })
        );
        dispatch(fetchAccount());
    }
    return;
};

export const logout = ({ history }) => dispatch => {
    localStorage.removeItem("token");
    dispatch(resetAccount());
    history.push("/");
};

export const forgetPassword = ({ history, Email, ...rest }) => (
    dispatch,
    getState
) => {
    const axios = require("./axios")();
    axios
        .post("/reset-passwords", {
            Email
        })
        .then(({ status, headers, data }) => {
            if (status === 200) {
                const { lang } = getState();
                const { locales } = lang;
                const { notifications } = lang[locales];

                dispatch(reset("forgetPassword"));
                history.push("/");
                Swal.fire({
                    type: "success",
                    title: notifications.forgetPassword.success,
                    onClose: () => window.location.reload()
                });
            }
        })
        .catch(error => console.error(error));
};
