import PropTypes from "prop-types";
import React from "react";
import BlankPage from "./BlankPage";
import { Header, Footer } from "../navigations";
// import { Helmet } from 'react-helmet';

class MainPage extends React.Component {
    render() {
        const { children } = this.props;
        return (
            <BlankPage>
                {/* <Helmet>
                    <title>
                        {
                            title ? `${title} | MG Friends` : `MG Friends`
                        }
                    </title>
                </Helmet> */}
                <Header />
                {children}
                <Footer />
            </BlankPage>
        );
    }
}

MainPage.propTypes = {
    title: PropTypes.string
};

MainPage.defaultProps = {};

export default MainPage;
