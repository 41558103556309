import React from "react";
import _ from "lodash";
import Card from "./Card";

const classes = ["item"];

export default ({ id, rewards, lang, category }) => (
    <div className="row">
        <div className="col-xs-12">
            <div className="titleVoucher">
                {lang.locales === "en" ? category.NameEn : category.Name}
            </div>
            <div className="carouselReward">
                <div
                    className="carousel slide"
                    id={`carousel-${id}`}
                    data-ride="carousel"
                >
                    <div className="carousel-inner">
                        {_.chunk(rewards, 3).map((rewards, index) => (
                            <div
                                key={index}
                                className={
                                    index === 0
                                        ? classes.concat("active").join(" ")
                                        : classes.join(" ")
                                }
                            >
                                <div className="row">
                                    {rewards.map((reward, subIndex) => (
                                        <Card key={subIndex} reward={reward} />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <a
                        data-slide="prev"
                        href={`#carousel-${id}`}
                        className="left carousel-control"
                    >
                        <i className="glyphicon glyphicon-menu-left" />
                    </a>
                    <a
                        data-slide="next"
                        href={`#carousel-${id}`}
                        className="right carousel-control"
                    >
                        <i className="glyphicon glyphicon-menu-right" />
                    </a>
                </div>
            </div>
        </div>
    </div>
);
