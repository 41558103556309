export const validateEditProfile = ({
    Name,
    Email,
    Phone,
    Birthdate,
    // Company,
    // Message,
    // recaptcha,
    ClaimTokopediaEmail,
    ClaimOvoPhone,
    ClaimBankAccount,
    ClaimMapPhone,
    ...rest
}) => {
    const errors = {};

    if (!Name) {
        errors.Name = `Required`;
    }

    if (!Email) {
        errors.Email = `Required`;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Email)) {
        errors.Email = `Invalid email address`;
    }

    if (!Phone) {
        errors.Phone = `Required`;
    } else if (Phone && !/^\d{11,}$/i.test(Phone)) {
        errors.Phone = `Invalid phone number`;
    }

    if (!Birthdate) {
        errors.Birthdate = `Required`;
    }

    // if (!Company) {
    //     errors.Company = `Required`;
    // }

    // if (!Message) {
    //     errors.Message = `Required`;
    // }

    // if (!recaptcha) {
    //     errors.recaptcha = `Recaptcha Required`;
    // }

    if (ClaimTokopediaEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(ClaimTokopediaEmail)) {
        errors.ClaimTokopediaEmail = `Invalid claim tokopedia email address`;
    }

    if (ClaimOvoPhone && !/^\d{9,}$/i.test(ClaimOvoPhone)) {
        errors.ClaimOvoPhone = "Invalid OVO phone number";
    }
    
    if (ClaimBankAccount && !/^\d{5,}$/i.test(ClaimBankAccount)) {
        errors.ClaimBankAccount = "Invalid bank account";
    }

    if (ClaimMapPhone && !/^\d{9,}$/i.test(ClaimMapPhone)) {
        errors.ClaimMapPhone = "Invalid MAP phone number";
    }

    return errors;
};

export const warnEditProfile = values => {
    const warnings = {};

    return warnings;
};

export const asyncValidateEditProfile = async values => {
    try {
        const axios = require("../actions/axios")();
        const { data: result } = await axios.post("/validate/editProfile", values);
        if (Object.keys(result).length > 0) {
            throw result;
        }
        return undefined;
    } catch (err) {
        throw err;
    }
};

export const submitValidateEditProfile = async ({
    Name,
    Email,
    Phone,
    Birthdate,
    // Company,
    // Message,
    // recaptcha,
    ClaimTokopediaEmail,
    ClaimOvoPhone,
    ClaimBankAccount,
    ClaimMapPhone,
    ...rest
}) => {
    try {
        const errors = {};

        if (!Name) {
            errors.Name = "Required";
            errors._error = "Name: Required";
        }
        if (!Email) {
            errors.Email = "Required";
            errors._error = "Email: Required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Email)) {
            errors.Email = "Invalid email address";
            errors._error = "Email: Invalid email address";
        }
        if (!Phone) {
            errors.Phone = "Required";
            errors._error = "Phone: Required";
        } else if (Phone && !/^\d{11,}$/i.test(Phone)) {
            errors.Phone = "Invalid phone number";
            errors._error = "Phone: Invalid phone number";
        }
        if (!Birthdate) {
            errors.Birthdate = `Required`;
            errors._error = "Birthdate: Required";
        }
        // if (!Company) {
        //     errors.Company = "Required";
        //     errors._error = "Company: required";
        // }
        // if (!Message) {
        //     errors.Message = "Required";
        //     errors._error = "Message: required";
        // }
        // if (!recaptcha) {
        //     errors.recaptcha = "Recaptcha Required";
        //     errors._error = "Recaptcha: required";
        // }
        if (ClaimTokopediaEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(ClaimTokopediaEmail)) {
            errors.ClaimTokopediaEmail = "Invalid claim tokopedia email address";
            errors._error = "Claim Tokopedia Email: Invalid claim tokopedia email address";
        }
        if (ClaimOvoPhone && !/^\d{11,}$/i.test(ClaimOvoPhone)) {
            errors.ClaimOvoPhone = "Invalid OVO phone number";
            errors._error = "Claim Ovo Phone: Invalid OVO phone number";
        }
        if (ClaimBankAccount && !/^\d{5,}$/i.test(ClaimBankAccount)) {
            errors.ClaimBankAccount = "Invalid bank account";
            errors._error = "Claim Bank Account: Invalid bank account";
        }

        if (ClaimMapPhone && !/^\d{11,}$/i.test(ClaimMapPhone)) {
            errors.ClaimMapPhone = "Invalid MAP phone number";
            errors._error = "MAP phone numbe: Invalid MAP phone number";
        }

        return errors;
    } catch (err) {
        throw err;
    }
};
