export const validateLogin = ({
    Email,
    Password
}) => {
    const errors = {}
    if (!Email) {
        errors.Email = `Required`
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Email)) {
        errors.Email = `Invalid email address`
    }

    if (!Password) {
        errors.Password = `Required`
    }

    return errors;
}

export const warnLogin = values => {
    const warnings = {}

    return warnings
}

export const asyncValidateLogin = async values => {
    try {
        const axios = require("../actions/axios")();
        const {data: result} = await axios.post('/validate/login', values);
        if (Object.keys(result).length > 0) {
            throw result;
        }
        return undefined;
    } catch (err) {
        throw err;
    }
}

export const submitValidateLogin = async ({
    Email,
    Password,
    ...rest
}) => {
    const errors = {}
    if (!Email) {
        errors.Email = `Required`;
        errors._error = `Required`;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Email)) {
        errors.Email = `Invalid email address`;
        errors._error = `Invalid email address`;
    } else if (!Password) {
        errors.Password = `Required`;
        errors._error = `Required`;
    }

    return errors;
}