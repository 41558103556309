export const SET_REWARD_CATEGORIES = "SET_REWARD_CATEGORIES";
export const RESET_REWARD_CATEGORIES = "RESET_REWARD_CATEGORIES";

export const SET_REWARDS = "SET_REWARDS";
export const RESET_REWARDS = "RESET_REWARDS";

export const SET_REWARD = "SET_REWARD";
export const RESET_REWARD = "RESET_REWARD";

export const setRewardCategories = payload => {
    return {
        type: SET_REWARD_CATEGORIES,
        payload
    };
};

export const resetRewardCategories = () => {
    return {
        type: RESET_REWARD_CATEGORIES
    };
};

export const setRewards = payload => {
    return {
        type: SET_REWARDS,
        payload
    };
};

export const resetRewards = () => {
    return {
        type: RESET_REWARDS
    };
};

export const setReward = payload => {
    return {
        type: SET_REWARD,
        payload
    };
};

export const resetReward = () => {
    return {
        type: RESET_REWARD
    };
};

export const fetchRewards = () => async dispatch => {
    try {
        // const Country = require("../config/config").getCountry();
        const axios = require("./axios")();
        const {
            data: { agent }
        } = await axios.get("/me");

        const options = {
            params: {
                // Country,
                Active: true,
                showAll: true,
                AgentCountry: agent.Country
            }
        };
        const { data: rewardCategories } = await axios.get(
            "/rewards/categories"
        );
        const { data: rewards } = await axios.get("/rewards", options);
        dispatch(setRewardCategories(rewardCategories));
        dispatch(setRewards(rewards));
        return;
    } catch (error) {
        console.error(error);
        return;
    }
};

export const fetchReward = id => async dispatch => {
    try {
        const axios = require("./axios")();
        const { data: reward } = await axios.get(`/rewards/${id}`);
        dispatch(setReward(reward));
        return;
    } catch (error) {
        console.error(error);
        return;
    }
};

export const redeemReward = ({ id: RewardID, history }) => async () => {
    try {
        const axios = require("./axios")();
        const values = {
            RewardID
        };
        await axios.post("/redemptions", values);
        history.push("/redemptions");
        return;
    } catch (error) {
        console.error(error);
        return;
    }
};
