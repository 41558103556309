import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { fetchAmazing, isLogin } from './actions';
import App from './App';
import ScrollToTop from './components/helpers/ScrollToTop';
import * as serviceWorker from './serviceWorker';
import store from './store';

store.dispatch(isLogin());
store.dispatch(fetchAmazing());

ReactDOM.render(
    <Provider store={ store }>
        <Router>
            <ScrollToTop>
                <App/>
            </ScrollToTop>
        </Router>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
