import React from 'react';
import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import { SignupForm } from '../forms';
import { connect } from 'react-redux';

const SignupModal = props => {
    return (
        <Modal size="small" name="signupModal" >
            <ModalHeader type="logo" title="Signup" />
            <ModalBody>
                <p
                    className="title text-center"
                    dangerouslySetInnerHTML={{ __html: props.lang[props.lang.locales].modals.signup.headings.one || null }}
                />
                <SignupForm />
            </ModalBody>
        </Modal>
    );
};

SignupModal.propTypes = {};

SignupModal.defaultProps = {};

const mapStateToProps = state => {
    return {
        lang: state.lang,
    };
};

export default connect(mapStateToProps)(SignupModal);