export const SET_AMAZING_PROGRESS = 'SET_AMAZING_PROGRESS';
export const RESET_AMAZING_PROGRESS = 'RESET_AMAZING_PROGRESS';

export const setAmazingProgress = payload => {
    return {
        type: SET_AMAZING_PROGRESS,
        payload,
    };
};

export const resetAmazingProgress = () => {
    return {
        type: RESET_AMAZING_PROGRESS,
    };
};

export const fetchAmazingProgress = () => async (dispatch, getState) => {
    try {
        const axios = require('./axios')();
        const amazing = getState().amazing;
        const {status, data: {rows}} = await axios.get(`/amazing/progresses/${amazing.id}`);
        if (status >= 200 && status < 300 && rows.length > 0) {
            return dispatch(setAmazingProgress(rows[0]));
        }
        return;
    } catch (error) {
        throw error;
    }
};