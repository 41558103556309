export const validateSignup = ({
    AgentCode,
    UserId,
    Phone,
    Email,
    Password,
    PasswordConfirm,
    ...rest
}) => {
    const errors = {};

    if (!AgentCode) {
        errors.AgentCode = 'Required';
    } else if (!/^[a-zA-Z0-9]+$/i.test(AgentCode)) {
        errors.AgentCode = 'Letters and numbers only';
    }

    if (!UserId) {
        errors.UserId = 'Required';
    } else if (!/^[a-zA-Z0-9_\s]+$/i.test(UserId)) {
        errors.UserId = 'Letters and numbers only';
    }

    if (!Email) {
        errors.Email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Email)) {
        errors.Email = 'Invalid email address';
    }

    if (Phone && isNaN(Number(Phone))) {
        errors.Phone = 'Numbers only';
    }

    if (!Password) {
        errors.Password = 'Required';
    }

    if (!PasswordConfirm) {
        errors.PasswordConfirm = 'Required';
    }

    if (Password && PasswordConfirm && Password !== PasswordConfirm) {
        errors.Password = 'Password did not match';
        errors.PasswordConfirm = 'Password did not match';
    }

    return errors;
}

export const warnSignup = values => {
    const warnings = {};
    
    return warnings;
}

export const asyncValidateSignup = async values => {
    try {
        const axios = require("../actions/axios")();
        const {data: result} = await axios.post('/validate/signup', values);
        if (Object.keys(result).length > 0) {
            throw result;
        }
        return undefined;
    } catch (err) {
        throw err;
    }
}

export const submitValidateSignup = async ({
    AgentCode,
    UserId,
    Email,
    Phone,
    Password,
    PasswordConfirm,
    ...rest
}) => {
    try {
        const errors = {};

        if (!AgentCode) {
            errors.AgentCode = 'Required';
            errors._error = 'Agent Code: required';
        } else if (!/^[a-zA-Z0-9]+$/i.test(AgentCode)) {
            errors.AgentCode = 'Letters and numbers only';
            errors._error = 'Agent Code: letters and numbers only';
        } else if (!UserId) {
            errors.UserId = 'Required';
            errors._error = 'User Id: required';
        } else if (!/^[a-zA-Z0-9]+$/i.test(UserId)) {
            errors.UserId = 'Letters and numbers only';
            errors._error = 'User Id: letters and numbers only';
        } else if (!Email) {
            errors.Email = 'Required';
            errors._error = 'Email: required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(Email)) {
            errors.Email = 'Invalid email address';
            errors._error = 'Email: invalid email address';
        } else if (Phone && isNaN(Number(Phone))) {
            errors.Phone = 'Numbers only';
            errors._error = 'Phone: numbers only';
        } else if (!Password) {
            errors.Password = 'Required';
            errors._error = 'Password: required';
        } else if (!PasswordConfirm) {
            errors.PasswordConfirm = 'Required';
            errors.PasswordConfirm = 'Password confirm: required';
        } else if (Password && PasswordConfirm && Password !== PasswordConfirm) {
            errors.Password = 'Password did not match';
            errors.PasswordConfirm = 'Password did not match';
            errors._error = 'Password did not match';
        }

        return errors;
    } catch (err) {
        throw err;
    }
}