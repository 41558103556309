import React from 'react';
import PropTypes from 'prop-types';

const modalSize = size => {
    switch (size) {
        case 'sm':
        case 'small':
            return 'modal-dialog modal-sm';
        case 'lg':
        case 'large':
            return 'modal-dialog modal-lg';
        default:
            return 'modal-dialog';
    }
}

const Modal = ({ children, size, name, ...props }) => {
    return (
        <div className={`modal fade ${name}`} tabIndex="-1" role="dialog" aria-labelledby={name}>
            <div className={modalSize(size)} role="document">
                <div className="modal-content">
                    { children }
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.any,
    size: PropTypes.string,
    show: PropTypes.bool,
    name: PropTypes.string.isRequired,
};

Modal.defaultProps = {
    show: false,
};

export default Modal;