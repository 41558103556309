import React from "react";
import { connect } from "react-redux";
import TableTransaction from "../small/TableTransaction";

import MainPage from './MainPage';

const TransactionPage = ({ lang, ...props }) => (
    <MainPage>
        <section className="content--1transaksi">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="form-transaksi">
                            <div className="form">
                                <h1>
                                    {lang[lang.locales].headings.transaction}
                                </h1>
                                <TableTransaction
                                    transactions={props.transactions}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </MainPage>
);

const mapStateToProps = state => {
    return {
        lang: state.lang
    };
};

export default connect(mapStateToProps)(TransactionPage);
