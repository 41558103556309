import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import Avatar from "../../containers/Avatar";
import { connect } from "react-redux";
import { fetchAmazing, setLocales } from "../../actions";

class MemberHeader extends React.Component {
    componentDidMount() {
        this.props.fetch();
    }
    render() {
        const { amazing, lang, location, onLangSwitch } = this.props;
        const { pathname } = location;
        const { locales } = lang;
        const { navigations } = lang[locales];
        let logo = "mg-logo.png";
        let menuNav = "menu-nav-login4";
        switch (pathname.split("/")[1]) {
            case "":
            case undefined:
            case null:
            case "rewards":
            case "landingpage":
            case "amazing-race":
                logo = "mg-logo.png";
                menuNav = "menu-nav-login";
                break;
            case "privacy-policy":
            case "hotel-promotion":
            case "promotion":
                logo = "mg-logo.png";
                menuNav = "menu-nav-login3";
                break;
            case "points-exchange-procedure":
                logo = "mg-logo.png";
                menuNav = "menu-nav-login2";
                break;
            case "mgtabletop":
                logo = "mg-logo.png";
                menuNav = "menu-nav-login4";
                break;
            default:
                break;
        }
        return (
            <section className={`${menuNav}`}>
                <nav className="menu-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-2">
                                <div>
                                    <Link to="/">
                                        <img
                                            src={`${
                                                process.env.PUBLIC_URL
                                            }/assets/images/logo/${logo}`}
                                            width="135"
                                            height="60"
                                            alt=""
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xs-10">
                                <ul className="list-menu">
                                    <li
                                        className={
                                            pathname &&
                                            (pathname === "/rewards" ||
                                                pathname === "/")
                                                ? `active`
                                                : undefined
                                        }
                                    >
                                        <NavLink to="/rewards">
                                            {navigations.rewards}
                                        </NavLink>
                                    </li>
                                    <li
                                        className={
                                            pathname &&
                                            pathname === "/transactions"
                                                ? `active`
                                                : undefined
                                        }
                                    >
                                        <NavLink to="/transactions">
                                            {navigations.transactions}
                                        </NavLink>
                                    </li>
                                    <li
                                        className={
                                            pathname &&
                                            pathname === "/promotions"
                                                ? `active`
                                                : undefined
                                        }
                                    >
                                        <NavLink to="/promotions">
                                            {navigations.promotions}
                                        </NavLink>
                                    </li>
                                    <li
                                        className={
                                            pathname &&
                                            pathname === "/redemptions"
                                                ? `active`
                                                : undefined
                                        }
                                    >
                                        <NavLink to="/redemptions">
                                            {navigations.redemptions}
                                        </NavLink>
                                    </li>
                                    <li
                                        className={
                                            pathname && pathname === "/privacy-policy"
                                                ? `active`
                                                : undefined
                                        }
                                    ></li>
                                    {amazing && amazing.id && (
                                        <li>
                                            <NavLink to="/amazing-race">
                                                <div className="campaign-menu">
                                                    <img
                                                        src={`${
                                                            process.env
                                                                .PUBLIC_URL
                                                        }/assets/images/logo/amazing-race-5-logo.png`}
                                                        alt=""
                                                        width="82"
                                                        height="32"
                                                    />
                                                </div>
                                            </NavLink>
                                        </li>
                                    )}
                                    <li className="divider-menu">|</li>
                                    <li>
                                        <Avatar />
                                    </li>
                                    <li className="dropdown">
                                        <a
                                            href="#/"
                                            className="dropdown-toggle"
                                            data-toggle="dropdown"
                                            role="button"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {locales === "en" ? (
                                                <i
                                                    className="flag-icon flag-icon-gb"
                                                    style={{
                                                        marginRight: "5px"
                                                    }}
                                                />
                                            ) : (
                                                <i
                                                    className="flag-icon flag-icon-id"
                                                    style={{
                                                        marginRight: "5px"
                                                    }}
                                                />
                                            )}
                                            <span className="caret" />
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a
                                                    href="#/"
                                                    onClick={onLangSwitch("id")}
                                                >
                                                    <i className="flag-icon flag-icon-id" />
                                                </a>
                                            </li>
                                            <li
                                                role="separator"
                                                className="divider"
                                            />
                                            <li>
                                                <a
                                                    href="#/"
                                                    onClick={onLangSwitch("en")}
                                                >
                                                    <i className="flag-icon flag-icon-gb" />
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.lang,
        amazing: state.amazing
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLangSwitch: locales => () => dispatch(setLocales(locales)),
        fetch: () => dispatch(fetchAmazing())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MemberHeader)
);
