const id = {
    actions: {
        save: "Simpan",
        login: "Masuk",
        signin: "Masuk",
        signup: "Daftar",
        register: "Daftar",
        send: "Kirim",
        follow: "Follow social media MG Friends, yuk!",
        claim: "Klaim",
        redeem: "Klaim",
        forget: "Lupa Password?"
    },
    navigations: {
        home: "Beranda",
        transactions: "Transaksi",
        transaction: "Transaksi",
        rewards: "Hadiah",
        reward: "Hadiah",
        login: "Masuk",
        signin: "Masuk",
        profile: "Profile",
        logout: "Keluar",
        signout: "Keluar",
        about: "Tentang MG Friends",
        aboutUs: "Tentang MG Friends",
        howToJoin: "Cara Bergabung",
        join: "Cara Bergabung",
        contactUs: "Hubungi Kami",
        contact: "Hubungi Kami",
        signup: "Daftar",
        register: "Daftar",
        redemptions: "Info Klaim Hadiah",
        redemption: "Info Klaim Hadiah",
        promotions: "Promosi Hotel",
        promotion: "Promosi Hotel",
        editProfile: "Ubah Profil",
        privacyPolicy: "Privacy Policy"
    },
    headings: {
        about: "Tentang MG Friends",
        howToJoin: "Gimana Caranya Jadi MG Friends?",
        join: "Gimana Caranya Jadi MG Friends?",
        testimonial: "Testimoni Agen",
        termCondition: "Syarat dan Ketentuan",
        redemptionProcedure: "Prosedur Penukaran",
        hotelPromotion: "Promosi Hotel",
        transaction: "Riwayat Transaksi",
        claim: "Info Klaim Hadiah"
    },
    misc: {
        building: "MG Group",
        address: "Jalan Majapahit No. 16, Jakarta Pusat 10160, Indonesia",
        phone: "(62 21) 2922 3933 ext 1123",
        fax: "(62 21) 2922 3999",
        mail: "admin@mgfriends.com",
        point: "Poin",
        table: {
            page: "Laporan per Halaman",
            show: "Tampilan {start} sampai {end} dari {count} entri",
            previous: "Sebelumnya",
            next: "Selanjutnya"
        }
    },
    notifications: {
        signup: {
            success:
                "Pendaftaran berhasil, Tolong tunggu persetujuan. Terima kasih.",
            error: "",
            warning: "",
            info: "",
            question: ""
        },
        changeProfile: {
            success: "Profil anda berhasil diperbaharui. Terima kasih.",
            error: "",
            warning: "",
            info: "",
            question: ""
        },
        changePassword: {
            success: "Kata sandi anda berhasil diperbaharui. Terima kasih.",
            error: "",
            warning: "",
            info: "",
            question: ""
        },
        changeAgent: {
            success:
                "Perbaharui Agent berhasil, Tolong tunggu persetujuan. Terima kasih",
            error: "",
            warning: "",
            info: "",
            question: ""
        },
        forgetPassword: {
            success:
                "Kata Sandi berhasil direset, Tolong cek Email kamu. Terima kasih",
            error: "",
            warning: "",
            info: "",
            question: ""
        },
        contact: {
            success: "Terima kasih atas pesan yang anda kirim",
            error: "Coba lagi",
            warning: "",
            info: "",
            question: ""
        }
    },
    validations: {},
    images: {},
    forms: {
        signup: {
            agentCode: "ID Agen MG bedbank",
            userId: "Nama Pengguna MG bedbank",
            phone: "Nomor Telepon",
            email: "Email",
            password: "Kata Sandi",
            passwordConfirm: "Ulang Kata Sandi"
        },
        login: {
            email: "Email",
            password: "Kata Sandi"
        },
        contact: {
            name: "Nama",
            email: "Email",
            phone: "Nomor Telp.",
            office: "Perusahaan",
            subject: "Pesan",
            captcha: "Klik tombol recaptcha terlebih dahulu"
        },
        changeProfile: {
            agent: "ID Agen",
            garudaMiles: "ID Garuda Miles",
            email: "Email",
            name: "Nama",
            bankName: "Nama Bank",
            bankAccount: "Rekening Bank",
            bankAccountName: "Atas Nama",
            phone: "Nomor Telepon",
            address: "Alamat",
            city: "Kota",
            birthDate: "Tanggal Lahir",
            picture: "Foto Profil",
            chooseFile: "Pilih Gambar",
            noChooseFile: "Tidak Ada Gambar",
            save: "Simpan"
        },
        changePassword: {
            password: "Kata Sandi Sekarang",
            newPassword: "Kata Sandi Baru",
            newPasswordConfirm: "Ulangi Kata Sandi Baru",
            save: "Simpan"
        },
        changeAgent: {
            currentAgent: "Kode Agen Sekarang",
            currentUser: "ID Pengguna MG Sekarang",
            newAgent: "Kode Agen Baru",
            noteAgent: "jika tidak diganti masukan dengan kode agen lama",
            newUser: "ID Pengguna MG Baru",
            noteUser: "jika tidak diganti masukan dengan kode agen lama",
            save: "Simpan"
        }
    },
    contents: {
        privacyPolicy: {
            subheading:
                "Privacy Policy",
            body: 
                `
                    <p>At MG Friends , we are committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, store, and disclose personal information when you use our loyalty program app ("MG Friends ").</p>
                    <h3>Information We Collect</h3>
                    <p>1.1 Personal Information: We may collect personal information such as your name, email address, phone number, and demographic information when you sign up for our loyalty program.</p>
                    <p>1.2 Loyalty Program Data: We collect information about your transactions, purchases, rewards, and other interactions with the loyalty program.</p>
                    <p>1.3 Device Information: We may collect device-specific information, such as your device type, operating system, and unique device identifiers.</p>
                    <h3>Use Of Information</h3>
                    <p>2.1 Personalization: We use your personal information to personalize your loyalty program experience, including providing relevant offers, rewards, and recommendations.</p>
                    <p>2.2 Communication: We may use your contact information to send you notifications, updates, and marketing materials related to the MG Friends loyalty program. You can opt out of receiving promotional communications.</p>
                    <p>2.3 Analytics: We use aggregated and anonymized data to analyze usage patterns, improve our App, and enhance our loyalty program. </p>
                    <h3>Sharing of Information</h3>
                    <p>3.1 Service Providers: We may share your information with trusted third-party service providers who assist us in operating and improving our App and loyalty program.</p>
                    <p>3.2 Legal Compliance: We may disclose your information if required by law or in response to a valid legal request.</p>
                    <h3>Data Security</h3>
                    <p>4.1 Security Measures: We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, loss, or alteration.</p>
                    <p>4.2 Data Retention: We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required by law.</p>
                    <h3>Third-Party Links</h3>
                    <p>Our App may contain links to third-party websites or services. We are not responsible for the privacy practices or content of those websites. We encourage you to review the privacy policies of any third-party websites you visit.</p>
                    <h3>Children's Privacy</h3>
                    <p>Our App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children. If you believe we have inadvertently collected information from a child, please contact us to have it removed.</p>
                    <h3>Changes to this Privacy Policy</h3>
                    <p>We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any material changes through the App or other communication channels. Your continued use of the App after the changes indicates your acceptance of the revised Privacy Policy.</p>
                    <h3>Contact Us</h3>
                    <p>If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us at <a href="mailto:support@mgfriends.com">support@mgfriends.com</a></p>
                `,
        },
        about: {
            subheading:
                "Sudah jadi Agen MG bedbank? Waktunya kamu bergabung juga di MG Friends ya. MG Friends merupakan tanda pertemanan antara MG dan kamu yang selalu setia booking hotel di MG bedbank.",
            body:
                'Setiap pesan hotel di <a href="https://mgbedbank.com" target="_blank"><span>mgbedbank.com</span></a> maka kamu akan mendapatkan <span>1 poin MGF</span> untuk setiap 1 malam.<br /><br />Kumpulkan poinnya dan tukarkan dengan <span>voucher tunai</span>, <span>voucher belanja</span>, <span>voucher hotel</span>, dan <span>berbagai produk menarik lainnya</span>.<br /><br />Poin MGF akan masuk ke dalam akunmu dan tercatat di riwayat transaksi 5 (lima) hari setelah tanggal check-out tamu. Jadi, Sering-sering ya booking hotel di MG, dijamin harganya paling murah, pilihan hotelnya banyak, bisa kumpulin poin dan dapet banyak HADIAH lagi.<br /><br />Pastikan saat kamu daftar di MG Friends, Agent ID dan User ID yang dimasukkan sama dengan yang kamu gunakan untuk booking hotel di <a href="https://mgbedbank.com" target="_blank"><span>mgbedbank.com</span></a> ya.'
        },
        join: {
            subheading: "Cara Bergabung",
            body:
                "<div class='col-xs-3 text-center'><p>Buka mgfriends.com,<br />Klik 'DAFTAR' disisi kanan atas</p></div><div class='col-xs-3 text-center'><p>Isi lengkap data kamu <br /> lalu klik 'DAFTAR'</p></div><div class='col-xs-3 text-center'><p>Pastikan Agent ID dan User ID kamu <br /> SAMA dengan yang digunakan <br /> untuk booking di <br /> mgbedbank.com</p></div><div class='col-xs-3 text-center'><p>Tim MG Friends akan melakukan <br /> verifikasi terhadap akunmu <br /> dan kamu akan menerima email <br /> konfirmasi jika sudah terdaftar</p></div>"
        },
        termCondition: {
            body: {
                list1: "Hadiah dan nilai poin dapat berubah sewaktu - waktu",
                list2:
                    "Penukaran poin dilakukan untuk masing-masing User ID. Poin kamu tidak bisa digabungkan dengan poin teman kamu ya",
                list3:
                    "Untuk penukaran produk, harap konfirmasi dulu ke Tim MG Friends agar bisa dicek ketersediaan barangnya",
                list4: "Baca lebih lengkap mengenai ",
                link: "prosedur penukaran"
            }
        },
        redemptionProcedure: {
            subheading: {
                cash: "Voucher Tunai",
                voucher: "Voucher Hotel dan Voucher Belanja",
                product: "Produk"
            },
            body: {
                cash:
                    "<li>Pastikan kamu telah mendaftarkan nomor akun bank yang benar dan aktif di halaman profil kamu</li><li>Pastikan poin yang kamu miliki sudah cukup untuk ditukar dengan nilai tunai yang kamu pilih</li><li>Setelah memilih, kamu akan menerima email konfirmasi jika permintaan sudah terkirim</li><li>Kamu juga akan menerima email yang menginformasikan bahwa permintaan telah disetujui dan sedang dalam proses</li><li>Kamu akan menerima uang senilai voucher tunai yang kamu pilih di akun bank paling lambat -/+ 30 hari kerja dari permintaan telah disetujui</li>",
                voucher:
                    "<li>Pastikan kamu telah mendaftarkan alamat yang benar dan lengkap di halaman profil kamu</li><li>Pastikan poin yang kamu miliki sudah cukup untuk ditukar dengan hadiah yang kamu pilih</li><li>Setelah memilih, kamu akan menerima email konfirmasi jika permintaan sudah terkirim</li><li>Kamu juga akan menerima email yang menginformasikan bahwa permintaan telah disetujui dan sedang dalam proses</li><li>Voucher Belanja / Hotel akan diterima -/+ 14 hari kerja dari permintaan disetujui</li><li>Ketentuan penggunaan voucher tergantung dari masing-masing perusahaan dan tercantum di dalam voucher</li><li>Voucher tidak dapat diganti jika rusak / hilang</li>",
                product:
                    "<li>Pastikan kamu telah mendaftarkan alamat yang benar dan lengkap di halaman profil kamu</li><li>Pastikan poin yang kamu miliki sudah cukup untuk ditukar dengan hadiah yang kamu pilih</li><li>Untuk penukaran produk, harap konfimasi dulu ke Tim MG Friends agar bisa dicek ketersediaan barangnya</li><li>Setelah memilih, kamu akan menerima email konfirmasi jika permintaan sudah terkirim</li><li>Kamu juga akan menerima email yang menginformasikan bahwa permintaan telah disetujui dan sedang dalam proses</li><li>Produk akan diterima maksimal -/+ 14 hari kerja dari permintaan disetujui</li>",
                back: "Kembali ke halaman hadiah"
            }
        },
        contact: {
            subheading: "Hubungi Kami",
            address:
                "Kantor Pusat<br /> Jl. Majapahit no. 16, <br /> Jakarta Pusat 10160, Indonesia"
        },
        reward: {
            subheading: "Daftar Penukaran Hadiah",
            statusBox: {
                point: "Poin Anda",
                status: "Status Anda"
            },
            modal: {
                subheading: "Hadiah",
                agent: "Nama Agen",
                userId: "ID Pengguna MG",
                note: "Poin kamu akan berkurang sebanyak",
                action: "Klaim Sekarang"
            }
        },
        hotelPromotion: {
            subheading:
                "Promo Hotel<br /> dan Bonus<br /> MG Friends<br /> Poin",
            termCondition:
                "Syarat & Ketentuan<div><ul><li class='text-tac'>List hotel diatas merupakan hotel yang punya MGF Xtrapoint</li><li class='text-tac'>Xtrapoint akan masuk ke akunmu berdasarkan tanggal check-out</li><li class='text-tac'>Poin MGF didapatkan dari pemesanan hotel di <a href='https://www.mgbedbank.com/'><span>mgbedbank.com</span></a></li></ul></div>"
        },
        claim: {
            pointInfo: {
                tab: "Info Penukaran Hadiah",
                table: {
                    date: "Tanggal",
                    description: "Deskripsi",
                    account: "Jenis",
                    amount: "Jumlah",
                    balance: "Sisa"
                }
            },
            redeemInfo: {
                tab: "Info Poin Lengkap",
                table: {
                    date: "Tanggal",
                    reward: "Hadiah",
                    value: "Jumlah",
                    point: "Poin",
                    status: "Status"
                }
            }
        },
        transaction: {
            table: {
                hotelName: "Nama Hotel",
                city: "Kota",
                country: "Negara",
                bookingDate: "Tanggal Pesan",
                checkIn: "Check In",
                checkOut: "Check Out",
                room: "Kamar",
                night: "Malam",
                points: "Poin",
                source: "Sumber"
            }
        },
        forgetPassword: {
            modal: {
                heading: "Reset Kata Sandi",
                content: "Harap masukkan email pribadi disini",
                button: "Reset Kata Sandi"
            }
        }
    },
    modals: {
        signup: {
            headings: {
                one: "Daftar jadi membernya<br />MG Friends yuks...",
            },
        },
    },
};

export default id;
