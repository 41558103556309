import React from 'react';
import { connect } from 'react-redux';

const AboutSection = ({lang}) => (
  <section className="content--1">
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <div className="about-mgfriends">
            <div className="text-center">
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/assets/images/icon/iconabout1.png`}
                alt=""
              />
              <h2> {lang[lang.locales].headings.about} </h2>
            </div>
            <div className="col-xs-7 col-xs-offset-5">
              <h3 dangerouslySetInnerHTML={{ __html: lang[lang.locales].contents.about.subheading }} />
              <h4 dangerouslySetInnerHTML={{ __html: lang[lang.locales].contents.about.body }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const mapStateToProps = state => {
  return {
    lang: state.lang,
  };
}

export default connect(mapStateToProps)(AboutSection);
