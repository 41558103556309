import React, { Component } from "react";
import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import { fetchPages, resetPages } from "../../actions/pages"
import LoginBox from "../../containers/LoginBox";
import StatusBox from "./StatusBox";

class CarouselSection extends Component {
  componentWillMount() {
    this.props.fetchPages()
  }

  componentWillUnmount() {
    this.props.resetPages()
  }
  
  render() {
    const carouselItems = this.props.pages.rows.map(({ id, Slug, Image, Custom }, index) => {
      return (
        <div key={id} className={`item ${index === 0 ? "active" : ""}`}>
          {Slug ? <Link to={ Custom ? Slug : `/landingpage/${Slug}` }>
            <div
              style={{
                backgroundImage: `url(${Image})`
              }}
            />
          </Link> : <div
              style={{
                backgroundImage: `url(${Image})`
              }}
            />}
        </div>
    )})
    return (
      <section className="home-carousel">
        <div
          id="home-slider"
          className="carousel slide carousel-fade"
          data-ride="carousel"
        >
          <div className="carousel-inner" role="listbox">
            { carouselItems }
          </div>
          <a
            className="left carousel-control"
            href="#home-slider"
            role="button"
            data-slide="prev"
          >
            <span
              className="glyphicon glyphicon-chevron-left"
              aria-hidden="true"
            />
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="right carousel-control"
            href="#home-slider"
            role="button"
            data-slide="next"
          >
            <span
              className="glyphicon glyphicon-chevron-right"
              aria-hidden="true"
            />
            <span className="sr-only">Next</span>
          </a>
        </div>
        {/* { this.props.isLogin ? <StatusBox /> : <LoginBox /> } */}
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLogin: state.account.isLogin,
    pages: state.pages,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPages: () => dispatch(fetchPages()),
    resetPages: () => dispatch(resetPages()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarouselSection);
