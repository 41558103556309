export const GET_ACCOUNT_STATEMENTS = "GET_ACCOUNT_STATEMENTS"
export const SET_ACCOUNT_STATEMENTS = "SET_ACCOUNT_STATEMENTS"
export const SET_ACCOUNT_STATEMENTS_PAGINATION = "SET_ACCOUNT_STATEMENTS_PAGINATION"
export const RESET_ACCOUNT_STATEMENTS = "RESET_ACCOUNT_STATEMENTS"

export const GET_ACCOUNT_STATEMENT = "GET_ACCOUNT_STATEMENT"
export const SET_ACCOUNT_STATEMENT = "SET_ACCOUNT_STATEMENT"
export const RESET_ACCOUNT_STATEMENT = "RESET_ACCOUNT_STATEMENT"

export const getAccountStatements = () => (dispatch, getState) => {
    return getState().accountStatements
}

export const setAccountStatements = (payload) => {
    return {
        type: SET_ACCOUNT_STATEMENTS,
        payload
    }
}

export const resetAccountStatements = () => {
    return {
        type: RESET_ACCOUNT_STATEMENTS
    }
}

export const getAccountStatement = () => (dispatch, getState) => {
    return getState().accountStatement
}

export const setAccountStatement = (payload) => {
    return {
        type: SET_ACCOUNT_STATEMENT,
        payload
    }
}

export const setAccountStatementsPagination = payload => {
    return {
        type: SET_ACCOUNT_STATEMENTS_PAGINATION,
        payload
    }
}

export const resetAccountStatement = () => {
    return {
        type: RESET_ACCOUNT_STATEMENT
    }
}

export const fetchAccountStatements = () => (dispatch, getState) => {
    const axios = require("./axios")()
    const {
        page,
        perPage
    } = getState().accountStatements.pagination
    axios.get("/account-statements", {
            params: {
                page,
                perPage
            }
        })
        .then(({
            status,
            headers,
            data: rows
        }) => {
            if (status === 200) {
                dispatch(setAccountStatements({
                    count: headers["x-total-count"],
                    rows
                }))
            }
        })
}

export const fetchAccountStatement = (id) => (dispatch, getState) => {
    if (!id) {
        return
    }
    const axios = require("./axios")()
    axios.get(`/account-statements/${id}`)
        .then(({
            status,
            data
        }) => {
            if (status === 200) {
                dispatch(setAccountStatement(data))
            }
        })
}